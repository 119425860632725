<template>
  <div>
    <!--
      => before #root (cloned)
    -->
    <div
      id="fp-team-header"
      class="f-bs4"
      style="display:none;"
    >
      <header-component />
    </div>

    <!--
      => before .login-body (cloned)
    -->
    <div
      id="fp-team-title"
      class="f-bs4"
      style="display:none;"
    >
      <h1 v-html="$t('team.intro.title')"></h1>
      <h2 v-html="$t('team.intro.subtitle')"></h2>
    </div>

    <!--
      => after .login-body (cloned)
    -->
    <div
      id="fp-team-intro"
      class="f-bs4"
      style="display:none;"
    >
      <intro />
    </div>

    <!--
      => after .login-body
    -->
    <portal
      target-el="#fp-team-prez"
      target-class="f-bs4"
    >
      <what />

      <limit />

      <how />

      <why />

      <who />
    </portal>
  </div>
</template>

<script>

import HeaderComponent from './Header.vue';
import Intro from './Intro.vue';
import What from './What.vue';
import Limit from './Limit.vue';
import How from '../../../../commons/components/hos/How.vue';
import Why from '../../../../commons/components/hos/Why.vue';
import Who from '../../../../commons/components/hos/Who.vue';
// import Outro from '../../../commons/components/hos/Outro.vue';

export default {
  components: {
    HeaderComponent,
    Intro,
    What,
    Limit,
    How,
    Why,
    Who,
    // Outro,
  },

  data() {
    return {
      interval: 5000,
    };
  },

  created() {
    const portals = ['header', 'title', 'intro', 'prez'];

    portals.forEach((k) => {
      document.querySelector('body')
        .insertAdjacentHTML('beforeend', `<div id="fp-team-${k}"></div>`);
    });

    // Court-circuiter ReactJS sur l'accès aux teams
    document
      .querySelectorAll('a[href*="/channels"]')
      .forEach((a) => {
        const link = a;
        link.onclick = (e) => {
          window.location.href = link.href;
          e.preventDefault();
        };
      });
  },

  mounted() {
    let ct = window.location.href.split('/');
    let bodyId = document.body.id || '';

    // Ajout d'un id pour savoir sur quelle page on est
    setInterval(() => {
      ct = window.location.href.split('/');
      bodyId = document.body.id || '';

      const ctId = (ct[4] === 'channels' || ct[4] === 'tutorial')
        ? `ct-${ct[4].split('?')[0]}`
        : `ct-${ct[3].split('?')[0]}`;

      if (ctId === 'ct-' && !document.body.classList.contains('outMM')) {
        document.body.classList.add('outMM');
      }

      /* Skip App/Browser landing page */
      if (document.querySelector('#root .get-app .get-app__continue')) {
        localStorage.setItem('__landing-preference__https://framateam.org', 'browser');
        document.querySelector('#root .get-app .get-app__continue').click();
      }

      if (bodyId !== ctId) {
        document.body.id = ctId;
      }

      if (bodyId !== ctId
        || (document.querySelector('.outMM'))
        || (document.querySelector('#ct-login.outMM') && !document.querySelector('#root #fp-team-title') && !document.querySelector('#root #how'))) {
        this.updateDisplay(ctId);
      }

      // Lien https://docs.framasoft.org/fr/mattermost/index.html
      document
        .querySelectorAll('a[href*="docs.mattermost.com/help"], a[href*="docs.mattermost.com/index"]')
        .forEach(a => Object.assign(a, {
          href: a.href.replace('docs.mattermost.com', 'docs.framasoft.org/fr/mattermost'),
        }));
    }, 1000);
  },

  methods: {
    updateDisplay(currentId) {
      const dom = {
        body: document.getElementsByTagName('body')[0],
        title: document.querySelector('.tutorial__steps h1'),
        vname: document.getElementById('valid_name'),
        sdesc: document.getElementById('site_description'),
        gitlab: document.querySelector('#gitlab'),
        lsection: document.querySelector('.login-body'),
        lid: document.getElementById('input_loginId'),
        lpwd: document.getElementById('input_password-input'),
        root: document.getElementById('root'),
      };

      if (/ct-(channels|admin-console|tutorial)/.test(currentId)) {
        if (dom.body.classList.contains('outMM')) {
          dom.body.classList.remove('outMM');
        }
        if (!dom.body.classList.contains('inMM')) {
          dom.body.classList.add('inMM');
        }
      }

      if (/ct-(select_team|reset_password|create_team|signup_user_complete|signup_email|login)/.test(currentId)) {
        if (dom.body.classList.contains('inMM')) {
          dom.body.classList.remove('inMM');
        }
        if (!dom.body.classList.contains('outMM')) {
          dom.body.classList.add('outMM');
        }
      }

      switch (currentId) {
        case 'ct-tutorial':
          if (dom.title) { dom.title.innerHTML = this.$t('color.team'); }
          break;

        case 'ct-signup_user_complete':
          if (dom.gitlab) {
            dom.gitlab.innerHTML = this.$i18n.t('team.create_framagit');
          }
          break;

        case 'ct-signup_email':
          if (dom.vname) {
            dom.vname.innerHTML = dom.vname.innerHTML
              .replace('des symboles \'.\', \'-\' et \'_\'', 'symboles <kbd>.</kbd>, <kbd>-</kbd> ou <kbd>_</kbd>');
          }
          break;

        case 'ct-login': {
          if (dom.lsection) {
            // ⚠️ vue-portal #fp-team-title
            if (!document.querySelector('#root #fp-team-title')) {
              dom.lsection
                .insertAdjacentElement('beforebegin', document.getElementById('fp-team-title'));
            }

            // ⚠️ vue-portal #fp-team-intro
            if (!document.querySelector('#root #fp-team-intro')) {
              document.querySelector('#root .header-footer-route-container')
                .insertAdjacentElement('beforeend', document.getElementById('fp-team-intro'));
            }

            // ⚠️ vue-portal #fp-team-prez
            if (!document.querySelector('#how')) {
              dom.root
                .insertAdjacentHTML('afterend', document.getElementById('fp-team-prez').innerHTML);
            }
            dom.lid.placeholder = this.$i18n.t('team.email');
            dom.lpwd.placeholder = this.$i18n.t('team.password');

            if (dom.gitlab
              && dom.gitlab.innerHTML !== this.$i18n.t('team.connect_framagit')) {
              dom.gitlab.innerHTML = this.$i18n.t('team.connect_framagit');

              if (document.querySelector('.hfroute-header .alternate-link')) {
                document.querySelector('.hfroute-header .alternate-link')
                  .setAttribute('class', 'ft-signup');
                dom.gitlab
                  .insertAdjacentElement('beforebegin', document.querySelector('.ft-signup'));
              }
            }
          }
          break;
        }

        default:
          // no default
          break;
      }

      // ⚠️ vue-portal #fp-team-header
      if (document.querySelector('.outMM')
        && dom.root
        && !document.querySelector('body > #ft-header')) {
        dom.root.insertAdjacentHTML('beforebegin', document.getElementById('fp-team-header').innerHTML);
      }

      dom.body.id = currentId;
    },
  },
};
</script>
