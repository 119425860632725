<template>
  <portal
    target-el="#fp-footer"
    target-class="f-bs4"
  >
    <footer
      v-show="$t('inframe') === 'false'"
      id="f-footer"
      class="clearfix d-print-none"
      :style="state.footer"
      role="contentinfo"
    >
      <b-container>
        <b-row class="mx-2 my-0 mx-sm-0">
          <b-col md="8">
            <b-row class="my-0">
              <!-- Framasoft -->
              <nav class="col-sm">
                <h1 v-html="$t('txt.soft')"></h1>
                <ul class="list-unstyled">
                  <li
                    v-for="key in $t('fnav.footer.frama')"
                    :key="key"
                  >
                    <div v-if="key === 'contact' && /(date|forms)/.test($t('site'))">
                      <PopoverContact from="footer" />
                    </div>
                    <div v-else>
                      <a
                        :href="!$te(`fnav.sites.${key}.link`)
                          ? $t(`link.${key}`)
                          : $t(`fnav.sites.${key}.link`)"
                        v-html="$t(`fnav.sites.${key}.name`)"
                      ></a>
                    </div>
                  </li>
                  <li>
                    <a
                      href="https://framaprout.org"
                      v-html="$t('Prout', '-k')"
                    ></a>
                  </li>
                </ul>
              </nav>
              <!-- Community -->
              <nav class="col-sm">
                <h1 v-html="$t('txt.community')"></h1>
                <ul class="list-unstyled">
                  <li>
                    <a
                      :href="$t('link.colibri')"
                      v-text="$t('fnav.sites.colibri.t1')"
                    ></a>
                  </li>
                  <li
                    v-for="key in $t('fnav.footer.community')"
                    :key="key"
                  >
                    <a
                      :href="!$te(`fnav.sites.${key}.link`)
                        ? $t(`link.${key}`)
                        : $t(`fnav.sites.${key}.link`)"
                      v-html="$t(`fnav.sites.${key}.name`)"
                    ></a>
                  </li>
                </ul>
              </nav>
              <!-- Framastuff -->
              <nav class="col-sm">
                <h1
                  v-html="$te(`txt.${$t('site')}`) && $t('site') !== 'soft'
                    ? $t(`txt.${$t('site')}`)
                    : `${$t('Site')} <small><i>(${$t('host')})</i></small>`"
                ></h1>
                <ul class="list-unstyled">
                  <li
                    v-for="key in $t('fnav.footer.site')"
                    :key="key"
                  >
                    <a
                      v-if="key === 'aide'"
                      :href="$te(`txt.${$t('site')}`)
                        ? $t(`fnav.sites.${key}.link`)
                          .replace(/#(aide|help)$/, `#${$t(`txt.${$t('site')}`, '-lL@')}`)
                        : $t(`fnav.sites.${key}.link`)"
                      @click.prevent.stop="toggleFramaSidebar();
                                           $parent.$children[1].view = 'help s-faq'"
                      v-html="$t(`fnav.sites.${key}.name`)"
                    ></a>
                    <a
                      v-else-if="key === 'credits' && $te(`txt.${$t('site')}`)"
                      :href="$t(`fnav.sites.${key}.link`)
                        .replace(/$/, `/#${$t('site')}`)"
                      v-html="$t(`fnav.sites.${key}.name`)"
                    ></a>
                    <a
                      v-else-if="key === 'doc'
                        && $te(`doc.${$t('site')}[${+($t('lang') === 'en')}]`)"
                      :href="$t(`fnav.sites.${key}.link`)
                        .replace(/$/, `/${$t(`doc.${$t('site')}[${+($t('lang') === 'en')}]`)}`)"
                      @click.prevent.stop="$parent.$children[1].modal.docs = true"
                      v-html="$t(`fnav.sites.${key}.name`)"
                    ></a>
                    <a
                      v-else
                      :href="!$te(`fnav.sites.${key}.link`)
                        ? $t(`link.${key}`)
                        : $t(`fnav.sites.${key}.link`)"
                      v-html="$t(`fnav.sites.${key}.name`)"
                    ></a>
                  </li>
                </ul>
              </nav>
            </b-row>
          </b-col>
          <b-col md="4">
            <h1 v-html="$t('fnav.categories.follow.title')"></h1>
            <ul class="list-inline">
              <li
                v-for="key in $t('fnav.footer.follow')"
                :key="key"
                v-b-popover:f-footer.top.hover.focus.html="$t(`fnav.sites.${key}.d1`)"
                :class="`list-inline-item fs_${key}`"
                :title="$t(`fnav.sites.${key}.t1`, '-t')"
              >
                <a
                  :href="!$te(`fnav.sites.${key}.link`)
                    ? $t(`link.${key}`)
                    : $t(`fnav.sites.${key}.link`)"
                >
                  <i :class="`${$t(`icon.${key}`)} fa-2x fa-fw fc-g8`"></i>
                  <span
                    class="sr-only"
                    v-html="!$te(`fnav.sites.${key}.name`)
                      ? $t(`txt.${key}`)
                      : $t(`fnav.sites.${key}.name`, '-t')"
                  ></span>
                </a>
              </li>
            </ul>
            <h2 v-html="$t('fnav.sites.newsletter.name')"></h2>
            <form
              action="https://listmonk.framasoft.org/subscription/form"
              method="post"
            >
              <label
                for="f-nl-email"
                class="sr-only"
                v-html="$t('txt.yourEmail')"
              ></label>
              <b-input-group
                size="sm"
                class="mb-2"
              >
                <b-form-input
                  id="f-nl-email"
                  aria-describedby="nlHelp"
                  name="email"
                  type="email"
                  size="40"
                  :title="$t('txt.typeYourEmail')"
                  :placeholder="$t('txt.yourEmail')"
                />
                <b-input-group-append>
                  <b-button
                    variant="dark"
                    type="submit"
                  >
                    <i class="fas fa-bell fc-g2"></i>
                    <span v-html="$t('txt.subscribe')"></span>
                    <span
                      class="sr-only"
                      v-html="$t('txt.toTheNewsletter')"
                    ></span>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <div style="display: none">
                <input
                  id="c9c93"
                  checked
                  name="l"
                  type="checkbox"
                  value="c9c93baf-1117-44f4-9e32-a37ed4a97f58"
                />
              </div>
            </form>
            <p
              id="nlHelp"
              v-html="$t('fnav.sites.newsletter.d2')"
            ></p>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </portal>
</template>

<script>
import PopoverContact from './PopoverContact.vue';

export default {
  components: {
    PopoverContact,
  },

  data() {
    return {
      state: {
        footer: 'position: relative',
      },
    };
  },

  created() {
    document.querySelector('body')
      .insertAdjacentHTML('beforeend', '<div id="fp-footer" style="display: none"></div>');
  },

  mounted() {
    // Footer position refresh on events
    window.addEventListener('click', this.footerPosition);
    window.addEventListener('load', this.footerPosition);
    window.addEventListener('resize', this.footerPosition);
    window.addEventListener('scroll', this.footerPosition);
  },

  methods: {
    footerPosition() {
      this.state.footer = (document.body.scrollHeight < window.innerHeight)
        ? 'position: absolute'
        : 'position: relative';
      setTimeout(() => { // au cas où une animation redimentionne le body
        this.state.footer = (document.body.scrollHeight < window.innerHeight)
          ? 'position: absolute'
          : 'position: relative';
      }, 800);
    },
  },
};
</script>

<style lang="scss">
#f-- #f-footer {
  padding:20px 0 20px 0;
  margin:0;
  background:var(--f-g4);
  color: var(--f-g9);
  font-size:14px;
  width:100%;
  max-width:100%;
  bottom:0;
  border-top:1px solid var(--f-g5);
  height:auto;
  text-align:left;
  font-family: 'Roboto', sans-serif;
  clear: both; /*  archive.~libre.org */

  .container {
    background:var(--f-g4);
    min-height:0px;
    padding: 0;
  }

  h1 {
    color: var(--f-g10);
    font-family: 'Tovari Sans';
    font-size: 1.5rem;
    font-weight:bold;
    margin-top:0;

    &::after {
      content: none;
    }
  }

  h2 {
    color: var(--f-g10);
    font-family: 'Tovari Sans';
    font-size: 1.5rem;
    font-weight:bold;

    &::after { /* contact */
      content: none;
    }
  }

  a {
    color: var(--f-g9);
    text-decoration: none;
    border-bottom: none;
    font-size: .875rem;
    background: transparent; /* contact */

    .fa-fw {
      padding: .5rem .375rem .25rem;
      width: 2rem;
      height: 2rem;
      text-decoration: none;
    }

    &:hover, &:focus {
      color: var(--f-g9);
      text-decoration: underline;

      .fa-fw {
        background: var(--f-f7);
        color: var(--f-d1);
        border-radius: .25rem;
      }
    }
  }

  #nlHelp {
    color: var(--f-g7);
    font-size: 80%;

    a {
      color: var(--f-g7);
      font-size: inherit;
      text-decoration: underline;
    }
  }

  .btn {
    margin-top: 0;
    height: auto; /* material */
  }

  .popover, .input-group {
    font-size: 14px;
  }

  .popover-header {
    font-size: 16px;
  }

  nav { /* material */
    background: none;
    box-shadow: none;
    line-height: 1.5;
    height: auto;

    li {
      float: none;
    }

    a {
      padding: 0;
    }
  }

  .popover {
    a {
      border-bottom: 2px solid var(--f-g5);

      &:hover, &:focus {
        border-bottom: 2px solid var(--f-f5);
        text-decoration: none;
      }
    }

    ul {
      padding-left: 0;
      margin-top: .5rem;
      margin-bottom: .5rem;
      list-style: none;

      li {
        position: relative;

        &::before {
          content: ' ';
          display: block;
          float: left;
          margin-top: 4px;
          margin-right: 3px;
          width: 12px;
          height: 12px;
          border-radius: 2px;
          border: 2px solid transparent;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M4.57 13.71a2.29 2.29 0 11-4.57 0 2.29 2.29 0 014.57 0zm6.28 1.7A10.86 10.86 0 00.6 5.14a.57.57 0 00-.6.57v1.72a.57.57 0 00.53.57A8 8 0 018 15.47c.02.3.27.53.57.53h1.72c.32 0 .59-.27.57-.6zm5.15 0A16.01 16.01 0 00.59 0 .57.57 0 000 .57V2.3c0 .3.24.56.55.57 6.83.28 12.31 5.76 12.59 12.59a.57.57 0 00.57.55h1.72c.32 0 .58-.27.57-.59z'/%3E%3C/svg%3E");
          background-position: center center;
          background-size: cover;
        }

        &:nth-child(1)::before {
          border-color: var(--f-o7);
          background-color: var(--f-o7);
        }

        &:nth-child(2)::before {
          border-color: var(--f-o6);
          background-color: var(--f-o6);
        }

        &:nth-child(3)::before {
          border-color: var(--f-f7);
          background-color: var(--f-f7);
        }

        &:nth-child(4)::before {
          border-color: var(--f-g6);
          background-color: var(--f-g6);
        }

        &:nth-child(5)::before {
          border-color: var(--f-o7);
          background-color: var(--f-o7);
        }
      }
    }
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0.25rem;
  }
}

/* Display footer only when CSS is ready */
#fp-footer[style*="display: none"] {
  display: block !important;
}

/* Keep position relative */
[data-url*="framagenda.org"],
[data-url*="framadate.org/create_"],
[data-url*="framadrive.org"],
[data-url*="frama.space"],
[data-url*="soutenir.framasoft.org"] {
  #fp-footer #f-footer {
    position: relative !important;
  }
}

/* No footer in… */
/* ------------------------------------------------------------------ */
[data-inframe="true"],

/* Ethercalc */
[data-url*="framacalc.org/"]:not([data-url*="framacalc.org/abc"]),

[data-url*="://framacarte.org"][data-url*="/map/"],                     /* uMap */
[data-url*="://beta.framacarte.org"][data-url*="/map/"],                /* uMap */
[data-url*="://framacarte.org"][data-url*="/login/"],                   /* uMap */
[data-url*="://beta.framacarte.org"][data-url*="/login/"],              /* uMap */
[data-url*="://framacolibri.org"],
[data-url*="://framacloud.org"],

/* Nextcloud */
// [data-url*="://framagenda.org"][data-url*="index.php/app"]:not([data-url*="registration"]),
// [data-url*="://framagenda.org"][data-url*="index.php/setting"],
[data-url*="://framadrive.org"][data-url*="index.php/app"]:not([data-url*="registration"]),
[data-url*="://framadrive.org"][data-url*="index.php/setting"],
[data-url*="://framaforms.org/enquete-sur-le-site-de-franceconnect"],   /* Enquête FranceConnect */

[data-url*="://wisemapping.framasoft.org/c/maps"][data-url*="/edit"],   /* Wisemapping */
[data-url*="://framindmap.org/c/maps"][data-url*="/edit"],              /* Wisemapping */
[data-url*="://framapiaf.org"]:not([data-url*="/about"]),
[data-url*="://framateam.org"],
[data-url*="://framatalk.org"]:not([data-url*="/abc"]),
[data-url*="://framatube.org"],
[data-url*="://framavox.org"]:not([data-url*="/abc"]),

[data-url*="://mypads.framapad.org"],                                   /* MyPads */
[data-url*="://mypads2.framapad.org"],                                  /* MyPads2 */
[data-url*=".framapad.org/p/"],                                         /* Etherpad */
[data-url$=".framapad.org/"],
[data-url*="://docs.framasoft.org"],
[data-url*="://forum.framasoft.org"],
[data-url*="://participer.framasoft.org"],
[data-url*="://status.framasoft.org"] {
  #f-footer,
  #f-footer[style] {
    display: none !important;
  }
}

/* Fix for quantum bug */
#f-nav + #fp-footer > #f-footer,
script[src$="nav.js"] + #fp-footer > #f-footer {
  display: none !important;
}

/**
 * For some reason on Framapetitions the above fix matches
 */
[data-url*=".framapetitions.org"] body #fp-footer #f-footer {
  display: block !important;
}
</style>
