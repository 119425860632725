import Vue from 'vue';
import VueI18n from 'vue-i18n';
import data from './data';

import merge from '../commons/plugins/merge';
import text from '../commons/plugins/text';

Vue.use(VueI18n);

Vue.use(merge);
Vue.use(text);

const config = process.env.CONFIG;
const wiki = (config.wiki.api !== '')
  ? require('./wiki')(config) // eslint-disable-line
  : {};

let lang = '';
const html = document.getElementsByTagName('html');
const meta = document.getElementsByTagName('script');

if (html[0].getAttribute('xml:lang')) {
  lang = html[0].getAttribute('xml:lang');
} else if (html[0].getAttribute('lang')) {
  lang = html[0].getAttribute('lang');
} else if (html[0].getAttribute('locale')) {
  lang = html[0].getAttribute('locale');
} else {
  for (let i = 0; i < meta.length; i += 1) {
    if ((meta[i].getAttribute('http-equiv') && meta[i].getAttribute('content')
      && meta[i].getAttribute('http-equiv').indexOf('Language') > -1)
      || (meta[i].getAttribute('property') && meta[i].getAttribute('content')
          && meta[i].getAttribute('property').indexOf('locale') > -1)) {
      lang = meta[i].getAttribute('content');
    }
  }
}

const messages = {};
const numberFormats = {};

messages.translations = config.translations.all;
messages.translations.available = config.translations.available;
let msg = {};
let req = {};

// Import translations
for (let i = 0; i < config.translations.available.length; i += 1) {
  const k = config.translations.available[i];
  /* eslint-disable */
  messages[k] = {};
  messages[k].lang = k;

  numberFormats[k] = {};
  numberFormats[k].eur = {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  };

  // Import data
  msg = {txt: {}, html: {}};
  msg = merge.$(msg, JSON.parse(JSON.stringify(data)));

  if (config.translations[k].includes('main')) {
    // Init with ../commons/translations/lg/main.yml
    req = require(`../commons/translations/${k}/main.yml`) || {};
    msg = merge.$(msg, JSON.parse(JSON.stringify(req)));
    // then import translations/lg/main.yml
    req = require(`./translations/${k}/main.yml`) || {};
    msg = merge.$(msg, JSON.parse(JSON.stringify(req)));
  }

  if (config.translations[k].includes('main2')) {
    req = require(`./translations/${k}/main2.yml`) || {};
    msg = merge.$(msg, JSON.parse(JSON.stringify(req)));
  }

  // translations/lg/*.yml
  for (let i = 0; i < config.translations[k].length; i += 1) {
    const file = config.translations[k][i];
    if (!/^main$/.test(file)) {
      msg[file] = msg[file] || {};
      req = require(`./translations/${k}/${file}.yml`) || {};
      msg[file] = merge.$(msg[file], JSON.parse(JSON.stringify(req)));
      if (/2$/.test(file)) {
        msg[file.replace(/2$/, '')] = merge.$(msg[file.replace(/2$/, '')], JSON.parse(JSON.stringify(req)));
      }
    }
  }

  // Copy dio
  if (msg.dio !== undefined && msg.dio.categories !== undefined) {
    Object.keys(msg.dio.categories).forEach((k) => {
      msg.fnav.categories.dio[`dio-${k}`] = msg.dio.categories[k];
    });

    Object.keys(msg.dio)
      .filter(k => !/(categories|piaf)/.test(k))
      .forEach((j) => {
        if (msg.fnav.sites[j] === undefined) {
          msg.fnav.sites[j] = {t1 : '', d1: ''};
        }
        msg.fnav.sites[j].t1 = msg.dio[j].lDesc;
        msg.fnav.sites[j].d1 = msg.dio[j].tDesc;
        if (msg.taxonomy[j] === undefined) {
          msg.taxonomy[j] = [];
        }
        msg.taxonomy[j].unshift(`c-dio-${msg.dio[j].category}`);
    });
  }

  // Copy wiki
  if (wiki[k] !== undefined) {
    msg = merge.$(msg, wiki[k]);
  }

  Object.keys(msg.color).forEach((j) => {
    if (msg.txt[j] === undefined) {
      msg.txt[j] = text.textTransform(msg.color[j], '-t');
    }
  });

  Object.keys(msg.link).forEach((j) => {
    if (msg.html[j] === undefined) {
      if (msg.color[j] !== undefined) {
        msg.html[j] = `<a href="@:link.${j}">@:color.${j}</a>`;
      } else if (msg.txt[j] !== undefined) {
        msg.html[j] = `<a href="@:link.${j}">@:txt.${j}</a>`;
      }
    }
  });

  messages[k] = merge.$(messages[k], msg);
  /* eslint-enable */
}

/* Check if lang is avalaible */
if (!messages.translations.available.includes(lang)) {
  if (messages.translations.available.includes(lang.substr(0, 2))) {
    /* lang === (fr_FR|en_GB|…) */
    lang = lang.substr(0, 2);
  } else {
    /* lang === (it|sv|ø|…) */
    lang = config.translations.default;
  }
} /*   lang === (en|fr) */

messages.base = `${__dirname}${config.base}`;

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: config.translations.default,
  messages,
  numberFormats,
  silentTranslationWarn: true,
});

export default i18n;
