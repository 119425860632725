<template>
  <section id="f-fb-participate">
    <div
      v-if="$te(`txt.${$t('site')}`)"
      class="subtitle"
      v-html="`${$t('feedback.participate.title')} <b>${$t(`txt.${$t('site')}`, '-t')}</b>`"
    ></div>

    <div v-html="$t('participate.dev', html)"></div>

    <b-row align-h="center">
      <b-col
        v-if="$te(`src.${$t('site')}`)"
        sm="6"
      >
        <a
          :href="$t(`src.${$t('site')}`)"
          class="btn btn-block"
        >
          <i class="fas fa-code-branch fa-lg fc-f7 fa-shape-circle fa-white fa-block-center"></i>
          <span v-html="$t(`soft.${$t('site')}`)"></span>
        </a>
      </b-col>
      <b-col
        v-if="$te(`git.${$t('site')}`)"
        sm="6"
      >
        <a
          :href="$t(`git.${$t('site')}`)"
          class="btn btn-block"
        >
          <i class="fab fa-git fa-lg fc-f7 fa-shape-circle fa-white fa-block-center"></i>
          <span v-html="$t(`color.${$t('site')}`)"></span>
        </a>
      </b-col>
    </b-row>

    <div v-html="$t('participate.other')"></div>
    <div v-html="$t('feedback.participate.next')"></div>
    <b-row align-h="center">
      <b-col sm="6">
        <a
          :href="`${$t('link.colibri')}/c/qualite/23/`"
          class="btn btn-block mt-3"
        >
          <i class="fas fa-comment fa-lg fc-o7 fa-shape-circle fa-white fa-block-center"></i>
          <span v-html="$t('feedback.participate.forum')"></span>
        </a>
      </b-col>
    </b-row>
  </section>
</template>
<script>
export default {
  data() {
    return {
      general: !(Object.keys(this.$t('txt')).indexOf(this.$t('site')) > -1),
      faq: [],
      html: {
        git: ` <i class="fab fa-lg fa-git" aria-hidden="true"></i>
          <span class="sr-only">git</span>`,
        source: ` <i class="fas fa-lg fa-code-branch" aria-hidden="true"></i>
          <span class="sr-only">source</span>`,
        link: `${this.$t('link.contact')}#issue-git`,
      },
      state: {
        faq: false,
        toggle: 'all',
      },
    };
  },

  methods: {
    showContact() {
      this.$parent.$parent.$parent.section = 'contact-participate';
      this.$parent.$parent.$parent.$refs['f-fb-top'].scrollIntoView();
    },
  },
};
</script>
