var render = function render(){var _vm=this,_c=_vm._self._c;return _c('portal',{attrs:{"target-el":"#fp-footer","target-class":"f-bs4"}},[_c('footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.$t('inframe') === 'false'),expression:"$t('inframe') === 'false'"}],staticClass:"clearfix d-print-none",style:(_vm.state.footer),attrs:{"id":"f-footer","role":"contentinfo"}},[_c('b-container',[_c('b-row',{staticClass:"mx-2 my-0 mx-sm-0"},[_c('b-col',{attrs:{"md":"8"}},[_c('b-row',{staticClass:"my-0"},[_c('nav',{staticClass:"col-sm"},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('txt.soft'))}}),_vm._v(" "),_c('ul',{staticClass:"list-unstyled"},[_vm._l((_vm.$t('fnav.footer.frama')),function(key){return _c('li',{key:key},[(key === 'contact' && /(date|forms)/.test(_vm.$t('site')))?_c('div',[_c('PopoverContact',{attrs:{"from":"footer"}})],1):_c('div',[_c('a',{attrs:{"href":!_vm.$te(`fnav.sites.${key}.link`)
                        ? _vm.$t(`link.${key}`)
                        : _vm.$t(`fnav.sites.${key}.link`)},domProps:{"innerHTML":_vm._s(_vm.$t(`fnav.sites.${key}.name`))}})])])}),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"https://framaprout.org"},domProps:{"innerHTML":_vm._s(_vm.$t('Prout', '-k'))}})])],2)]),_vm._v(" "),_c('nav',{staticClass:"col-sm"},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('txt.community'))}}),_vm._v(" "),_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('a',{attrs:{"href":_vm.$t('link.colibri')},domProps:{"textContent":_vm._s(_vm.$t('fnav.sites.colibri.t1'))}})]),_vm._v(" "),_vm._l((_vm.$t('fnav.footer.community')),function(key){return _c('li',{key:key},[_c('a',{attrs:{"href":!_vm.$te(`fnav.sites.${key}.link`)
                      ? _vm.$t(`link.${key}`)
                      : _vm.$t(`fnav.sites.${key}.link`)},domProps:{"innerHTML":_vm._s(_vm.$t(`fnav.sites.${key}.name`))}})])})],2)]),_vm._v(" "),_c('nav',{staticClass:"col-sm"},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$te(`txt.${_vm.$t('site')}`) && _vm.$t('site') !== 'soft'
                  ? _vm.$t(`txt.${_vm.$t('site')}`)
                  : `${_vm.$t('Site')} <small><i>(${_vm.$t('host')})</i></small>`)}}),_vm._v(" "),_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.$t('fnav.footer.site')),function(key){return _c('li',{key:key},[(key === 'aide')?_c('a',{attrs:{"href":_vm.$te(`txt.${_vm.$t('site')}`)
                      ? _vm.$t(`fnav.sites.${key}.link`)
                        .replace(/#(aide|help)$/, `#${_vm.$t(`txt.${_vm.$t('site')}`, '-lL@')}`)
                      : _vm.$t(`fnav.sites.${key}.link`)},domProps:{"innerHTML":_vm._s(_vm.$t(`fnav.sites.${key}.name`))},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.toggleFramaSidebar();
                                         _vm.$parent.$children[1].view = 'help s-faq'}}}):(key === 'credits' && _vm.$te(`txt.${_vm.$t('site')}`))?_c('a',{attrs:{"href":_vm.$t(`fnav.sites.${key}.link`)
                      .replace(/$/, `/#${_vm.$t('site')}`)},domProps:{"innerHTML":_vm._s(_vm.$t(`fnav.sites.${key}.name`))}}):(key === 'doc'
                      && _vm.$te(`doc.${_vm.$t('site')}[${+(_vm.$t('lang') === 'en')}]`))?_c('a',{attrs:{"href":_vm.$t(`fnav.sites.${key}.link`)
                      .replace(/$/, `/${_vm.$t(`doc.${_vm.$t('site')}[${+(_vm.$t('lang') === 'en')}]`)}`)},domProps:{"innerHTML":_vm._s(_vm.$t(`fnav.sites.${key}.name`))},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.$parent.$children[1].modal.docs = true}}}):_c('a',{attrs:{"href":!_vm.$te(`fnav.sites.${key}.link`)
                      ? _vm.$t(`link.${key}`)
                      : _vm.$t(`fnav.sites.${key}.link`)},domProps:{"innerHTML":_vm._s(_vm.$t(`fnav.sites.${key}.name`))}})])}),0)])])],1),_vm._v(" "),_c('b-col',{attrs:{"md":"4"}},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('fnav.categories.follow.title'))}}),_vm._v(" "),_c('ul',{staticClass:"list-inline"},_vm._l((_vm.$t('fnav.footer.follow')),function(key){return _c('li',{directives:[{name:"b-popover",rawName:"v-b-popover:f-footer.top.hover.focus.html",value:(_vm.$t(`fnav.sites.${key}.d1`)),expression:"$t(`fnav.sites.${key}.d1`)",arg:"f-footer",modifiers:{"top":true,"hover":true,"focus":true,"html":true}}],key:key,class:`list-inline-item fs_${key}`,attrs:{"title":_vm.$t(`fnav.sites.${key}.t1`, '-t')}},[_c('a',{attrs:{"href":!_vm.$te(`fnav.sites.${key}.link`)
                  ? _vm.$t(`link.${key}`)
                  : _vm.$t(`fnav.sites.${key}.link`)}},[_c('i',{class:`${_vm.$t(`icon.${key}`)} fa-2x fa-fw fc-g8`}),_vm._v(" "),_c('span',{staticClass:"sr-only",domProps:{"innerHTML":_vm._s(!_vm.$te(`fnav.sites.${key}.name`)
                    ? _vm.$t(`txt.${key}`)
                    : _vm.$t(`fnav.sites.${key}.name`, '-t'))}})])])}),0),_vm._v(" "),_c('h2',{domProps:{"innerHTML":_vm._s(_vm.$t('fnav.sites.newsletter.name'))}}),_vm._v(" "),_c('form',{attrs:{"action":"https://listmonk.framasoft.org/subscription/form","method":"post"}},[_c('label',{staticClass:"sr-only",attrs:{"for":"f-nl-email"},domProps:{"innerHTML":_vm._s(_vm.$t('txt.yourEmail'))}}),_vm._v(" "),_c('b-input-group',{staticClass:"mb-2",attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"f-nl-email","aria-describedby":"nlHelp","name":"email","type":"email","size":"40","title":_vm.$t('txt.typeYourEmail'),"placeholder":_vm.$t('txt.yourEmail')}}),_vm._v(" "),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"dark","type":"submit"}},[_c('i',{staticClass:"fas fa-bell fc-g2"}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('txt.subscribe'))}}),_vm._v(" "),_c('span',{staticClass:"sr-only",domProps:{"innerHTML":_vm._s(_vm.$t('txt.toTheNewsletter'))}})])],1)],1),_vm._v(" "),_c('div',{staticStyle:{"display":"none"}},[_c('input',{attrs:{"id":"c9c93","checked":"","name":"l","type":"checkbox","value":"c9c93baf-1117-44f4-9e32-a37ed4a97f58"}})])],1),_vm._v(" "),_c('p',{attrs:{"id":"nlHelp"},domProps:{"innerHTML":_vm._s(_vm.$t('fnav.sites.newsletter.d2'))}})])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }