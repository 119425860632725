<template>
  <section
    id="fsb-info"
    :class="`pb-4 px-2 info-${site}`"
  >
    <b-button
      variant=" pl-0 text-uppercase mb-2"
      @click="goBack()"
    >
      <i class="fas fa-chevron-left"></i>
      <span v-html="$t('txt.back')"></span>
    </b-button>

    <h2 class="text-center">
      <a
        :href="!$te(`fnav.sites.${site}.link`)
          ? $t(`link.${site}`)
          : $t(`fnav.sites.${site}.link`)"
      >
        <img
          v-if="$te(`dio.${site}`)"
          alt=""
          class="d-block mx-auto mb-2"
          :src="`${$t('baseurl')}img/icons/ati/sites/${site}.svg`"
        />
        <img
          v-if="site !== '' && !$te(`dio.${site}`)"
          alt=""
          class="d-block mx-auto mb-2 rounded-circle"
          :src="`${$t('baseurl')}img/icons/cards/${site}.jpg`"
        />
        <p
          v-html="$te(`color.${site}`)
            ? $t(`txt.${site}`)
            : $t(`fnav.sites.${site}.t1`)"
        ></p>
      </a>
    </h2>
    <p v-html="$t(`fnav.sites.${site}.d1`)"></p>
    <p
      v-if="hasScreen()"
      class="text-center"
    >
      <img
        alt=""
        class="img-fluid"
        :src="`${$t('baseurl')}img/screens/${site}.png`"
      />
    </p>
    <p v-if="$te(`src.${site}`)">
      Service basé sur
      <a
        :href="$t(`src.${site}`)"
        v-text="$t(`soft.${site}`)"
      ></a>
    </p>
    <b-alert
      v-if="/(calc|date|memo|pad|talk)/.test(site)"
      show
      variant=" fb-g3 mb-0 mt-4 p-2 d-flex align-items-center border-0"
    >
      <a
        :href="$t('link.entraide')"
        aria-hidden="true"
      >
        <img
          :src="`${$t('baseurl')}img/icons/chatons/${site}.svg`"
          alt=""
          class="rounded-circle ml-1 mr-3"
          style="width: 70px; height: 70px; min-width: 70px;"
        />
      </a>
      <p
        class="mb-0 py-2"
        v-html="$t('entraide')"
      ></p>
    </b-alert>
    <b-alert
      v-if="Object.keys($t('deframa')).includes(site)"
      show
      variant="warning mb-0 mt-4 p-2 d-flex align-items-center border-0"
    >
      <p
        class="mb-0"
        v-html="$t('deframa.alert2', {
          service: $t(`color.${site}`),
          date: date($t(`deframa.${site}[1]`)),
          link: `https://alt.framasoft.org/fr/${$t(`txt.${site}`, '-l')}`
        })"
      ></p>
    </b-alert>
  </section>
</template>

<script>
export default {
  props: {
    site: {
      type: String,
      required: true,
    },
  },

  methods: {
    date(date) {
      return new Date(date).toLocaleDateString(this.$t('lang'), {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },

    goBack() {
      this.$emit('goBack');
    },

    hasScreen() {
      return [
        'agenda', 'board', 'calc', 'carte', 'date', 'drive', 'forms',
        'games', 'git', 'listes', 'mindmap',
        'pad', 'piaf', 'slides', 'talk', 'team', 'vox',
      ].includes(this.site);
    },
  },
};
</script>
