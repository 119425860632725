<template>
  <section id="f-fb-faq">
    <!-- Answers -->
    <div
      :class="`${/^all$/.test(state.toggle) ? 'all' : ''} ${
        search !== '' ? 'filter' : 'nofilter'
      }`"
    >
      <div
        v-if="this.$te(`txt.${this.$t('site')}`)"
        class="subtitle"
        v-html="`${$t('feedback.faq')} <b>${$t(`txt.${$t('site')}`)}</b>`"
      ></div>
      <div
        v-else
        class="subtitle"
        v-html="$t('feedback.mainFaq')"
      ></div>

      <!-- Reminders ~date/~forms -->
      <b-card
        v-if="/(date|forms)/.test($t('site'))"
        id="f-fb-reminder"
        no-body
        class="bg-light mb-4"
      >
        <b-card-body>
          <div v-html="$t(`reminder.${$t('site')}`)"></div>
        </b-card-body>
      </b-card>

      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in faq"
          :key="index"
          :active="state.toggle === item.id"
          :variant="item.variant"
        >
          <a
            v-show="state.toggle === item.id"
            href="#!"
            class="pull-right close"
            aria-hidden="true"
            onclick="return false"
            @click.prevent.stop="toggleAnswer(item.id)"
          >×</a>
          <a
            :id="item.id"
            :href="`${$t('link.contact')}/faq/#${item.id}`"
            class="toggle"
            onclick="return false"
            @click.prevent.stop="toggleAnswer(item.id)"
          ></a>
          <h3
            class="list-group-item-heading"
            @click="toggleAnswer(item.id)"
            v-html="item.question"
          ></h3>
          <div
            class="list-group-item-text"
            v-html="item.answer"
          ></div>
          <p class="text-center mb-0">
            <b-button
              v-show="state.toggle === item.id"
              variant="light"
              size="sm"
              @click="toggleAnswer(item.id)"
            >
              <span v-text="$t('txt.close')"></span>
            </b-button>
          </p>
        </b-list-group-item>
      </b-list-group>
      <p
        v-if="!state.mainFaq && state.faq && state.toggle === 'all'"
        class="mb-0 mt-3"
      >
        <b-button
          block
          variant="outline-warning text-center"
          @click="addMainFaq()"
        >
          <span v-html="$t('feedback.more')"></span>
        </b-button>
      </p>
    </div>

    <!-- No answer ? -->
    <div
      v-show="state.toggle === 'all'"
      class="no-answer"
    >
      <p
        v-show="state.faq"
        v-html="$t('feedback.noanswer')"
      ></p>
      <div v-html="$t('feedback.help.next')"></div>
      <b-row>
        <b-col sm="6">
          <a
            :href="`${$t('link.contact')}/#${$t('lname')}`"
            class="btn btn-block"
            onclick="return false;"
            @click.prevent.stop="showContact()"
          >
            <i class="fas fa-envelope fa-lg fc-o7 fa-shape-circle fa-white fa-block-center"></i>
            <span v-html="`${$t('feedback.contact')} ${$t('color.soft')}`"></span>
          </a>
        </b-col>
        <b-col sm="6">
          <a
            class="btn btn-block"
            :href="$t('link.colibri')"
          >
            <i class="fas fa-comment fa-lg fc-o7 fa-shape-circle fa-white fa-block-center"></i>
            <span v-html="$t('feedback.forum')"></span>
          </a>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    status: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      general: !(Object.keys(this.$t('txt')).indexOf(this.$t('site')) > -1),
      category: this.$t(`txt.${this.$t('site')}`, '-l'),
      mainFaq: [],
      faq: [],
      state: {
        mainFaq: false,
        faq: false,
        toggle: 'all',
      },
    };
  },

  watch: {
    open(newValue) {
      if (newValue) {
        this.loadFaq();
      }
    },
    search(newValue) {
      if (newValue !== '') {
        this.searchUpdate();
      }
    },
  },

  methods: {
    loadFaq() {
      let q = this.$t('faq.ranking.misc');
      for (let i = 0; i < q.length; i += 1) {
        this.mainFaq[i] = {
          id: q[i],
          question: this.$t(`faq.misc.${q[i]}.q`),
          answer: this.$t(`faq.misc.${q[i]}.a`)
            .replace(/<a href="https:\/\/contact.framasoft.org([\S\s]*?)">([\S\s]*?)<\/a>/g, '$2'),
          variant: '',
        };
      }

      if (!this.state.faq) {
        /* Site Faq */
        if (!this.general && this.$te(`faq.ranking.${this.category}`)) {
          q = this.$t(`faq.ranking.${this.category}`);
          for (let i = 0; i < q.length; i += 1) {
            this.faq[i] = {
              id: q[i],
              question: this.$t(`faq.${this.category}.${q[i]}.q`),
              answer: this.$t(`faq.${this.category}.${q[i]}.a`)
                .replace(/<a href="https:\/\/contact.framasoft.org([\S\s]*?)">([\S\s]*?)<\/a>/g, '$2'),
              variant: '',
            };
          }
        }
        if (this.faq.length === 0) {
          this.faq = this.mainFaq;
          this.state.mainFaq = true;
        }
        if (this.status.length > 0) {
          this.faq = this.status.concat(this.faq);
        }
        this.state.faq = true;
      }
    },

    toggleAnswer(id) {
      this.state.toggle = ['all', id][+(this.state.toggle === 'all')];
      this.$emit('scrolltop');
    },

    showContact() {
      if (/(framateam|framapiaf|alt.framasoft)/.test(this.$t('host'))
        || /(user\/regist)/.test(this.$t('url'))) {
        // Open contact.framasoft.org when CSP are too stricts
        // or when the site is spammed
        // (on ~libre, ~mindmap sign up form and alt~ )
        window.open(`${this.$t('link.contact')}/#${this.$t('lname')}`, '_blank');
      } else {
        // Display contact form
        this.$emit('scrolltop');
        this.$emit('goToContact');
      }
    },

    addMainFaq() {
      this.faq = this.faq.concat(this.mainFaq);
      this.state.mainFaq = true;
    },

    searchUpdate() {
      const words = this.$t(this.search.replace(/\+ ?/, '_'), '-kLl@')
        .split('_');
      const reg = new RegExp(`(${words.join('|')})`, 'g');

      if (!this.state.mainFaq) {
        /* search = more results */
        this.addMainFaq();
      }
      this.state.toggle = 'all';

      for (let i = 1; i < this.faq.length; i += 1) {
        const content = this.$t(
          `${this.faq[i].question} ${this.faq[i].answer}`,
          '-kLl@',
        );
        if (reg.test(content)) {
          this.faq[i].variant = `${this.faq[i].variant
            .replace(/search/g, '')} search`;
        } else {
          this.faq[i].variant = this.faq[i].variant.replace(/search/g, '');
        }
      }
    },
  },
};
</script>
