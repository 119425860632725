<template>
  <span class="btn btn-block btn-light p-0">
    <b-button
      v-if="!$te(`fnav.sites.${site}.d1`)
        || view === 'grid'
        || $t(`taxonomy.${site}[0]`) === 'c-follow'"
      block
      :href="/^fnav/.test($t(`fnav.sites.${site}.link`))
        ? $t(`link.${site}`)
        : $t(`fnav.sites.${site}.link`)"
      rel="noopener"
      target="_blank"
      variant=" "
    >
      <i :class="`${$t(`icon.${site}`)} fa-lg fa-fw fa-block-center`"></i>
      <span
        v-html="$te(`color.${site}`)
          ? $t(`color.${site}`)
          : $t(`fnav.sites.${site}.t1`)"
      ></span>
      <i class="fas fa-up-right-from-square fa-xs"></i>
      <span
        class="sr-only"
        v-html="`(${$t('txt.newWindow')})`"
      ></span>
    </b-button>
    <b-button
      v-else
      block
      variant=" "
      @click="goToInfo(site)"
    >
      <i :class="`${$t(`icon.${site}`)} fa-lg fa-fw fa-block-center`"></i>
      <span
        v-html="$te(`color.${site}`)
          ? $t(`color.${site}`)
          : $t(`fnav.sites.${site}.t1`)"
      ></span>
      <span
        class="sr-only"
        v-html="`(${$t('txt.infos')})`"
      ></span>
    </b-button>
  </span>
</template>

<script>
export default {
  props: {
    site: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      default: '',
    },
  },

  methods: {
    goToInfo(site) {
      this.$root
        .$children[0] // App.vue
        .$children[0] // Nav.vue
        .$children.find(child => child.$options._componentTag === 'sidebar-menu') // eslint-disable-line no-underscore-dangle
        .$emit('goToInfo', site);
    },
  },
};
</script>
