<template>
  <a
    :id="`f-${from}-contact`"
    :class="from === 'header' ? 'dropdown-item' : ''"
    :href="$t('link.contact')"
    onclick="return false"
    :target="from === 'header' ? '_blank' : '_self'"
    @click.stop.prevent="$refs[`f-${from}-contact-popover`].$emit('open')"
  >
    <i
      v-if="from === 'header'"
      :class="`${$t('icon.contact')} fa-lg fa-fw`"
    ></i>
    <span v-html="$t('fnav.sites.contact.name')"></span>

    <span v-if="from === 'header'">
      <i class="fas fa-up-right-from-square new-window"></i>
      <span
        class="sr-only"
        v-html="`(${$t('txt.newWindow')})`"
      ></span>
    </span>

    <b-popover
      :ref="`f-${from}-contact-popover`"
      :target="`f-${from}-contact`"
      :title="$t('reminder.title')"
      triggers="click"
      :placement="from === 'header' ? 'leftbottom' : 'top'"
      :container="`f-${from}`"
    >
      <b-row>
        <b-col v-html="$t(`reminder.${$t('site')}`)" />
      </b-row>
      <b-row class="border-top mx-0">
        <b-col
          cols="12"
          class="px-0 py-2"
        >
          <p
            class="m-0"
            v-html="$t(`reminder.label`)"
          ></p>
        </b-col>
        <b-col
          cols="6"
          class="p-0 pr-1"
        >
          <b-button
            variant="outline-light text-secondary border"
            size="sm"
            block
            :href="$t('link.contact')"
            v-html="$t('reminder.btn.soft')"
          />
        </b-col>
        <b-col
          cols="6"
          class="p-0 pl-1"
        >
          <b-button
            variant="outline-light text-secondary border"
            size="sm"
            block
            href="#"
            @click="$refs[`f-${from}-contact-popover`].$emit('close')"
            v-html="$t(`reminder.btn.${$t('site')}`)"
          />
        </b-col>
      </b-row>
    </b-popover>
  </a>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      required: true,
    },
  },
};
</script>
