import main from './data/main.yml';
import merge from '../commons/plugins/merge';

const config = process.env.CONFIG;
const scripts = document.getElementsByTagName('script');

let data = {}; // eslint-disable-line

for (let i = 0; i < config.commons.enabled.length; i += 1) {
  const req = require(`../commons/data/${config.commons.enabled[i]}.yml`) || {}; // eslint-disable-line
  data[config.commons.enabled[i]] = merge
    .$(data[config.commons.enabled[i]], JSON.parse(JSON.stringify(req)));
}

data = merge.$(data, JSON.parse(JSON.stringify(main)));

Object.assign(data, {
  '/': `/${config.base.replace(/(.+)/, '$1/')}`,
  base: config.base,
  baseurl: config.url,
  config,
  date: config.date,
  hash: window.location.hash.replace('#', ''),
  host: window.location.host,
  inframe: window.top.location !== window.self.document.location ? 'true' : 'false',
  url: window.location.href,
  year: {
    current: (new Date().getFullYear()).toString(),
    next: (new Date().getFullYear() + 1).toString(),
  },
});

if (/\/nav.js$/.test(scripts[scripts.length - 1].src)) {
  data.self = new URL(scripts[scripts.length - 1].src, data.url).href;
} else {
  const findNav = /nav.src = '(.*)';\n/g.exec(scripts[scripts.length - 1].innerText);
  data.self = (findNav !== null && findNav[1] !== undefined)
    ? new URL(findNav[1], data.url).href
    : 'https://framasoft.org/nav/nav.js';
}

if (!/^http/.test(data.baseurl)) {
  data.baseurl = data.self.split('/').slice(0, -1).join('/').replace(/\/?$/, '/');
}

/** Only for nav */
data.site = data.host.replace(/^(www|test)\./i, '').replace(/\.(com|net|org|fr|pro)$/i, '');
data.name = data.site[0].toUpperCase() + data.site.slice(1).replace('.framasoft', ''); // Nom du service
data.lname = data.name.toLowerCase();

// [site] Cleaning (shortest name for config)
data.site = data.site
  .replace(/framage/i, 'age')
  .replace(/(\.framasoft|frama\.)/i, '')
  .replace(/framin/i, 'min')
  .replace(/frama/i, '')
  .replace(/localhost:8080/i, 'calc');

// [site] Subdomains
if (/framacalc/.test(data.host)) { data.site = 'calc'; }
if (/framadate/.test(data.host)) { data.site = 'date'; }
if (/framatalk/.test(data.host)) { data.site = 'talk'; }

// [site] Exceptions Framapad
if (/(mypads2?|beta3).framapad.org/.test(data.host)) {
  data.site = 'mypads';
  data.name = 'Framapad';
}
if ((/.framapad/.test(data.host) && !/mypads./.test(data.host))
  || (/(mypads2?|beta3).framapad/.test(data.host) && /\/p\//.test(data.url))) {
  data.site = 'etherpad';
  data.name = 'Framapad';
}
// [site] Exception Beta Framaforms
if (/beta\.framaforms\.org/.test(data.host)) {
  data.name = 'Framaforms Beta';
}

export default data;
