<template>
  <div
    id="f-nav"
    class="f-bs4"
    style="display: none;"
  >
    <Nav />
  </div>
</template>

<script>
import Nav from './components/Nav.vue';

export default {
  name: 'FNav',
  components: {
    Nav,
  },

  data() {
    return {
      i18n: this.$t('config.mode') !== 'production' ? this.$i18n.messages : '',
    };
  },
};
</script>
