<template>
  <a
    v-show="btn"
    id="f-backtop"
    href="#"
    :title="$t('txt.backTop')"
    @click.prevent="backTop()"
  >
    <i class="fas fa-chevron-up fa-lg fa-fw fc-light"></i>
    <span
      class="sr-only"
      v-html="$t('txt.backTop')"
    ></span>
  </a>
</template>

<script>
export default {
  data() {
    return {
      btn: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.btn = window.pageYOffset > window.innerHeight;
    });
  },

  methods: {
    backTop() {
      document.body.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style>
#f-backtop {
  position: fixed;
  bottom: 0px;
  right: 42px;
  z-index: 1102;
  height: 26px;
  line-height: 26px;
  border-radius: 26px 26px 0 0;
  padding: 0 17px;
  background-color: #4a5568 !important;
  color: #fff !important;
  opacity: 0.4;
  border: none;
}

#f-backtop i {
  margin: 0 -7px;
}

#f-backtop:hover,
#f-backtop:focus {
  opacity: 1;
}

@media (max-width: 640px) {
  #f-backtop {
    right: 20px;
  }
}

/* Hide when sidbar is open */
#f--[data-sidebar="true"] #f-backtop,
/* and remove other "top" links */
#back-to-top[style], /* dio */
#topcontrol[style], /* ~blog, ~book, ~zic */
.scroll-top-wrapper.show { /* ~listes */
  display: none !important;
}
</style>
