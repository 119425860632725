<template>
  <b-modal
    v-if="config[0] !== ''"
    id="f-modal-info"
    v-model="state.info"
    :static="true"
    :lazy="true"
    :title="config[0]"
    :header-close-label="$t('txt.close')"
    :cancel-title-html="$t('txt.close')"
    cancel-variant="light"
    :ok-title-html="$t('txt.nevershow')"
    @esc="close()"
    @backdrop="close()"
    @headerclose="close()"
    @cancel="close()"
    @ok="nevershow()"
  >
    <div v-html="config[1]"></div>
  </b-modal>
</template>

<script>
export default {
  data() {
    let config = ['', ''];

    // Test config
    if (/\/test\/verbose.*\.html$/.test(this.$t('url'))) {
      config = [this.$t('modal.test.title'), this.$t('modal.test.md')];
    }

    // Sites config
    switch (this.$t('site')) {
      case 'etherpad':
        if (/(beta\.framapad)/.test(this.$t('host'))) {
          config = [this.$t('modal.beta-pad.title'), this.$t('modal.beta-pad.md')];
        }
        break;
      case 'calc':
        if (this.is.before('2023/3/29') && !/^\/abc\//.test(this.$t('url'))) {
          config = [this.$t('modal.calc.title'), this.$t('modal.calc.md')];
        }
        break;
      case 'date':
        if (/(beta\.framadate)/.test(this.$t('host'))) {
          config = [this.$t('modal.beta-date.title'), this.$t('modal.beta-date.md')];
        }
        if (/(preprod\.framadate)/.test(this.$t('host'))) {
          config = [this.$t('modal.preprod-date.title'), this.$t('modal.preprod-date.md')];
        }
        break;
      case 'excalidraw.lab':
        // Special warning modal for Excalidraw
        config = [this.$t('modal.lab-excalidraw.title'), this.$t('modal.lab-excalidraw.md')];
        break;
      default:
        // no-default
        break;
    }

    return {
      config,
      state: {
        info: !this.cookie('r', 'modal-info'),
      },
    };
  },

  methods: {
    close() {
      this.cookie('w', 'modal-info', true, 604800000); /* 7 days */
    },

    nevershow() {
      this.cookie('w', 'modal-info', true);
    },
  },
};
</script>

<style lang="scss">
/* No modal at all */
/* ------------------------------------------------------------------ */
[data-inframe="true"],
[data-url*="://framacarte.org"][data-url*="/map/"],                     /* dans uMap */
[data-url*="://beta.framacarte.org"][data-url*="/map/"],                /* dans uMap */
[data-url*="://framacolibri.org"],
[data-url*="://framateam.org"],
[data-url*="://framavox.org"]:not([data-url*="/abc"]),

[data-url*="://status.framasoft.org"],

[data-url*="://degooglisons-internet.org"],
[data-url*="://contributopia.org"] {
  #f-modal-info[style],
  #f-modal-info[style] + .modal-backdrop {
    display: none !important;
  }

  .modal-open {
    overflow: auto;
  }
}
</style>
