<template>
  <b-badge
    v-if="status.length > 0"
    pill
    :variant="status[0].variant"
  >
    <span v-text="status.length"></span>
  </b-badge>
</template>

<script>
export default {
  data() {
    return {
      state: {
        status: false,
      },
      id: this.$te(`status.${this.$t('site')}`) ? this.$t(`status.${this.$t('site')}`) : '0',
      status: [],
    };
  },

  created() {
    this.checkStatus();
  },

  methods: {
    checkStatus() {
      /* Status */
      if (this.id !== '0' && !this.state.status) {
        fetch(`https://status.framasoft.org/api/v1/components?id=${this.id}`)
          .then(res => res.json())
          .then((data) => {
            if (data.data[0].status > 1) { // There is a incident
              const variant = data.data[0].status < 4 ? 'warning' : 'danger';
              this.loadLastIncident(this.id, variant);
            }
            this.state.status = true;
          })
          .catch((err) => { console.error(err); }); // eslint-disable-line
      }
      this.loadScheduledIncidents();
      /*
        Bad: status send to
          => b-sidebar => menu
        and then imported in faq
      */
      this.$parent.$parent.status = this.status;
    },

    loadLastIncident(id, variant) {
      fetch(`https://status.framasoft.org/api/v1/incidents?component_id=${id}&sort=id&order=desc`)
        .then(res => res.json())
        .then((data) => {
          if (data.data.length > 0) {
            this.status.unshift({
              id: 'incident_0',
              question: data.data[0].name,
              answer: `<div>${this.$t(data.data[0].message.replace('\r\n', '<br>'), '-k')}</div>`,
              variant,
            });
          }
        })
        .catch((err) => { console.error(err); }); // eslint-disable-line
    },

    loadScheduledIncidents() {
      fetch('https://status.framasoft.org/api/v1/incidents?component_id=0&sort=id&order=desc')
        .then(res => res.json())
        .then((data) => {
          for (let i = 0; i < data.data.length; i += 1) {
            if (data.data[i].scheduled_at) {
              const day = data.data[i].scheduled_at.replace(/-/g, '/').substr(0, 10);
              const nextDay = new Date(new Date(day).getTime() + (2 * 24 * 60 * 60 * 1000))
                .toISOString()
                .split('T')[0]
                .replace(/-/g, '/');
              if (this.is.before(nextDay) && this.$te(`txt.${this.$t('site')}`)) {
                const reg = new RegExp(`(${this.$t(`txt.${this.$t('site')}`, '-Ll')})`, 'g');
                const content = this.$t(`${data.data[i].message} ${data.data[i].name}`, '-kLl');
                if (reg.test(content)) {
                  this.status.unshift({
                    id: `maintenance_${i + 1}`,
                    question: data.data[i].name,
                    answer: `<div>${this.$t(data.data[i].message.replace('\r\n', '<br>'), '-k')}</div>`,
                    variant: 'info',
                  });
                }
              }
            }
          }
        })
        .catch((err) => { console.error(err); }); // eslint-disable-line
    },
  },
};
</script>
