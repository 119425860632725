var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"btn btn-block btn-light p-0"},[(!_vm.$te(`fnav.sites.${_vm.site}.d1`)
      || _vm.view === 'grid'
      || _vm.$t(`taxonomy.${_vm.site}[0]`) === 'c-follow')?_c('b-button',{attrs:{"block":"","href":/^fnav/.test(_vm.$t(`fnav.sites.${_vm.site}.link`))
      ? _vm.$t(`link.${_vm.site}`)
      : _vm.$t(`fnav.sites.${_vm.site}.link`),"rel":"noopener","target":"_blank","variant":" "}},[_c('i',{class:`${_vm.$t(`icon.${_vm.site}`)} fa-lg fa-fw fa-block-center`}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$te(`color.${_vm.site}`)
        ? _vm.$t(`color.${_vm.site}`)
        : _vm.$t(`fnav.sites.${_vm.site}.t1`))}}),_vm._v(" "),_c('i',{staticClass:"fas fa-up-right-from-square fa-xs"}),_vm._v(" "),_c('span',{staticClass:"sr-only",domProps:{"innerHTML":_vm._s(`(${_vm.$t('txt.newWindow')})`)}})]):_c('b-button',{attrs:{"block":"","variant":" "},on:{"click":function($event){return _vm.goToInfo(_vm.site)}}},[_c('i',{class:`${_vm.$t(`icon.${_vm.site}`)} fa-lg fa-fw fa-block-center`}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$te(`color.${_vm.site}`)
        ? _vm.$t(`color.${_vm.site}`)
        : _vm.$t(`fnav.sites.${_vm.site}.t1`))}}),_vm._v(" "),_c('span',{staticClass:"sr-only",domProps:{"innerHTML":_vm._s(`(${_vm.$t('txt.infos')})`)}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }