import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import PortalVue from 'portal-vue';

import is from '../commons/plugins/is';
import cookie from '../commons/plugins/cookie';
import merge from '../commons/plugins/merge';
import text from '../commons/plugins/text';

import i18n from './i18n';
import App from './App.vue';

import './scss/main.scss';

Vue.use(BootstrapVue);
Vue.use(PortalVue);

Vue.use(is);
Vue.use(cookie);
Vue.use(merge);
Vue.use(text);

// Override $t() for markdown and formatting
Vue.prototype.$t = (key, locale, values) => text.$t(key, locale, values, i18n);

Vue.prototype.toggleFramaSidebar = () => {
  const visible = (document.getElementsByTagName('html')[0]
    .getAttribute('data-sidebar') || false) === 'true';
  document.getElementsByTagName('html')[0].setAttribute('data-sidebar', !visible);
};

const loadNav = () => {
  if (document.getElementById('f-nav') === null) {
    document.querySelector('body')
      .insertAdjacentHTML('afterbegin', '<div id="f-nav"></div>');
  }
  new Vue({ // eslint-disable-line no-new
    el: '#f-nav',
    i18n,
    mounted() {
      // You'll need this for renderAfterDocumentEvent.
      document.dispatchEvent(new Event('render-event'));
    },
    render: h => h(App),
  });
};

const scripts = document.getElementsByTagName('script');
if (!/\/nav.js$/.test(scripts[scripts.length - 1].src)) {
  loadNav();
} else {
  window.addEventListener('DOMContentLoaded', () => { loadNav(); });
}
