<template>
  <div>
    <Meta />
    <sidebar-menu v-if="!/:\/\/framagenda.org\/apps\/registration/.test($t('url'))" />

    <alert />

    <!-- <modal-campaign
      v-if="is.before('2023/12/31') && $t('lang') === 'fr'
        && (/\/abc\//.test($t('url'))
        || /mypads\/\?\/mypads$/.test($t('url'))
        || /framadate.org\/.+\/admin/.test($t('url'))
        || /framaforms.org\/(node|user)\//.test($t('url'))
        || /framindmap.org\/c\/maps\/$/.test($t('url'))
        || /framagames.org/.test($t('url')))"
    /> -->

    <!-- <modal-dorlotons
      v-if="is.after('2024/6/27') && is.before('2024/7/10')
        && (/\/abc\//.test($t('url'))
        || /mypads\/\?\/mypads$/.test($t('url'))
        || /framadate.org\/.+\/admin/.test($t('url'))
        || /framaforms.org\/(node|user)\//.test($t('url'))
        || /framindmap.org\/c\/maps\/$/.test($t('url'))
        || /framagames.org/.test($t('url')))"
    /> -->

    <modal-info />

    <!-- <modal-joke
      v-if="is.after('2023/3/31') && is.before('2023/4/2') && $t('lang') === 'fr'
        && (/\/abc\//.test($t('url'))
        || /(framagames.org|framasoft.org)/.test($t('url')))"
    /> -->

    <!-- <modal-support
      v-if="$t('lang') === 'fr'
        && (/\/abc\//.test($t('url'))
        || /mypads\/\?\/mypads$/.test($t('url'))
        || /framadate.org\/.+\/admin/.test($t('url'))
        || /framaforms.org\/(node|user)\//.test($t('url'))
        || /framindmap.org\/c\/maps\/$/.test($t('url'))
        || /framagames.org/.test($t('url')))"
    /> -->

    <framateam v-if="/:\/\/framateam.org/.test($t('url'))" />

    <footer-menu />
    <back-top />
  </div>
</template>

<script>
import Meta from './Meta.vue';

import SidebarMenu from './sidebar/Menu.vue';

import Alert from './alert/Main.vue';
// import ModalCampaign from './modal/Campaign.vue';
// import ModalDorlotons from './modal/Dorlotons.vue';
import ModalInfo from './modal/Info.vue';
// import ModalJoke from './modal/Joke.vue';
// import ModalSupport from './modal/Support.vue';

// Need vue-portal
import Framateam from './sites/team/Main.vue';

import FooterMenu from './FooterMenu.vue';

import BackTop from './BackTop.vue';

export default {
  components: {
    Meta,
    SidebarMenu,

    Alert,
    // ModalCampaign,
    // ModalDorlotons,
    ModalInfo,
    // ModalJoke,
    // ModalSupport,

    Framateam,

    FooterMenu,
    BackTop,
  },

  data() {
    return {
      // Init nav
      version: '23.7.4', // n° version de la nav
      js() {},
    };
  },

  created() {
    // Add [data-*] attributes for CSS
    const html = document.getElementsByTagName('html');
    html[0].setAttribute('data-url', this.$t('url'));
    html[0].setAttribute('data-inframe', this.$t('inframe'));
    html[0].setAttribute('id', 'f--');

    // Load CSS
    const fcss = document.createElement('link');
    Object.assign(fcss, {
      rel: 'stylesheet',
      href: `${this.$t('baseurl')}main.css`,
    });
    document.getElementsByTagName('head')[0].appendChild(fcss);
  },

  mounted() {
    const html = document.getElementsByTagName('html');
    setInterval(() => {
      const lang = html[0].getAttribute('lang');
      if (lang
        && this.$i18n.locale !== lang
        && this.$i18n.locale !== lang.substring(0, 2)
        && this.$i18n.messages.translations) {
        if (this.$i18n.messages.translations.available.includes(lang)) {
          /* lang === (fr|en|…) */
          this.$i18n.locale = lang;
        } else if (this.$i18n.messages.translations.available.includes(lang.substring(0, 2))) {
          /* lang === (fr_FR|en_GB|…) */
          this.$i18n.locale = lang.substring(0, 2);
        } else {
          /* lang === (it|sv|ø|…) */
          this.$i18n.locale = this.$i18n.fallbackLocale;
        }
      }
    }, 1000);

    /* ********** Custom JavaScript ********** */
    this.customJS(this.$t('site'));
    if (typeof this.js === 'function') {
      this.js();
    }
  },

  methods: {
    isLang(lg, browser) {
      if (browser !== 'b') { // Langue de la page
        let lang = '';
        const html = document.getElementsByTagName('html');
        const meta = document.getElementsByTagName('script');

        if (html[0].getAttribute('lang')) {
          lang = html[0].getAttribute('lang');
        } else if (html[0].getAttribute('locale')) {
          lang = html[0].getAttribute('locale');
        } else {
          for (let i = 0; i < meta.length; i += 1) {
            if ((meta[i].getAttribute('http-equiv') && meta[i].getAttribute('content')
              && meta[i].getAttribute('http-equiv').indexOf('Language') > -1)
              || (meta[i].getAttribute('property') && meta[i].getAttribute('content')
                  && meta[i].getAttribute('property').indexOf('locale') > -1)) {
              lang = meta[i].getAttribute('content');
            }
          }
        }
        return (lang.substr(0, 2).toLowerCase() === lg);
      }
      // Langue du navigateur
      const userLang = navigator.languages
        || [navigator.language || navigator.userLanguage];
      for (let i = 0; i < userLang.length; i += 1) {
        if (userLang[i].substring(0, 2).toLowerCase() === lg) {
          return true;
        }
      }
      return false;
    },

    customJS(site) {
      switch (site) {
        case 'carte':
          /* if (jQuery !== undefined) {
            setInterval(() => {
              const url = new RegExp('(https://(beta.)?framacarte.org/[a-z]+/map/anonymous-edit(.*))', 'gi');
              const alert = jQuery('.umap-edit-enabled #map #umap-alert-container > div').text();
              if (/anonymous-edit/.test(alert) && !/⚠️/.test(alert)) {
                jQuery('.umap-edit-enabled #map #umap-alert-container > div').html(`⚠️ ${
                  alert.replace(url, `
                  <p>
                    <a href="$1" aria-hidden="true">🔗</a>
                    <input
                      style="max-width: 600px; margin: .5rem; display: inline"
                      type="text"
                      value="$1"
                    />
                  </p>`)}`);
                jQuery('.umap-edit-enabled #map #umap-alert-container').css({
                  'background-color': 'var(--f-v2)',
                  color: 'var(--f-v8)',
                  'font-size': '1rem',
                  top: '60px',
                });
                jQuery('.umap-edit-enabled #map #umap-alert-container .umap-close-link span')
                  .hide();
                jQuery('.umap-edit-enabled #map #umap-alert-container .umap-close-icon')
                  .css('background-position', '-52px -13px');
              }
            }, 500);
          } */
          break;

        case 'date':
          this.js = function date() {
            const alert = document.querySelector('#message-container .alert-danger');
            if (alert) {
              alert.innerHTML = alert.innerHTML.replace('Merci de réessayer.',
                `Merci de réessayer. (NB&nbsp;: il est possible aussi qu’il s’agisse
                d’un bug, mais
                <a href="${this.$t('link.contact')}/fr/faq/#date-vote-not-counted" target="_blank">
                  <u>il peut être contourné</u>
                </a>)`);
            }
          };
          break;

        case 'docs':
          if (this.$t('inframe') === 'true') {
            this.js = function docs() {
              /* Disable sidebar menu in iframe (still toggleable) */
              if (document.querySelector('.with-summary')) {
                document.querySelector('.with-summary').classList.remove('with-summary');
              }
            };
          }
          break;

        case 'libre':
          this.js = function libre() {
            if (this.$t('inframe') === 'true') {
              document.querySelectorAll('a').forEach(a => Object.assign(a, { target: '_blank' }));
            }
            const register = document.getElementById('user-register-form');
            if (register) {
              register.insertAdjacentHTML('beforeEnd',
                `<p class="alert alert-warning"><b>Rappel&nbsp;:</b> ${this.$t('color.libre')} est
                seulement un annuaire collaboratif de ressources libres.
                Il ne permet <strong>pas de créer un compte unique</strong> pour
                accéder à l’ensemble des services de Framasoft.</p>`);
            }
          };
          break;

        case 'mindmap':
          this.js = function mindmap() {
            const html = document.getElementsByTagName('html');
            const lang = navigator.languages || [navigator.language];

            // Link to delete account
            /* if (this.$t('hash') === 'deleteAccountPanel') {
              // a cookie to keep info after sign in
              this.cookie('w', 'f-mindmap-delete-account', true, 604800000);
            }

            if (this.cookie('r', 'f-mindmap-delete-account') === 'true'
              && /\/c\/maps\//.test(this.$t('url'))) {
              setTimeout(() => {
                jQuery('#userSettingsBtn').click();
                const switchTab = setInterval(() => {
                  if (jQuery('#settings-dialog-modal .nav-tabs').length > 0) {
                    jQuery('#settings-dialog-modal .nav-tabs li').removeClass('active');
                    jQuery('#settings-dialog-modal .tab-content .tab-pane')
                      .removeClass('active in');
                    jQuery('#settings-dialog-modal .nav-tabs li').last().addClass('active');
                    jQuery('#settings-dialog-modal .tab-content .tab-pane').last()
                      .addClass('active in');
                    // Tab has been seen
                    clearInterval(switchTab);
                    this.cookie('w', 'f-mindmap-delete-account', false, 604800000);
                  }
                },
                500);
              }, 1000);
            } */

            if (!html[0].getAttribute('lang')
              && document.getElementById('SignIn')) {
              html[0].lang = (lang[0].substring(0, 2) === 'fr') ? 'fr' : 'en';
              this.$i18n.locale = html[0].lang;
              return; /* why this is here ?! */
            }
            if (document.getElementById('userSettingsBtn')
              && document.getElementById('userSettingsBtn').innerHTML === 'Compte') {
              html[0].lang = (html[0].getAttribute('lang') !== 'fr') ? 'fr' : 'en';
              this.$i18n.locale = html[0].lang;
            }
          };
          break;

        // <framapad> --------------------------------------------------------
        case 'etherpad': // dans Etherpad
          this.js = function etherpad() {
            jQuery('#loading').delay(2000).append(`
              <p class="small">Si le pad refuse de s’afficher, essayez de télécharger<br>
              l’export <a href="${this.$t('url').split('?')[0]}/export/html">html</a>
              ou <a href="${this.$t('url').split('?')[0]}/export/txt">txt</a>
              de votre document.</p>`);
          };
          break;
          // </framapad> -------------------------------------------------------

        case 'vox':
          this.js = function vox() {
            setInterval(() => {
              if (/^fr/.test(this.$i18n.locale)) {
                /* help.loomio -> docs.frama */
                document
                  .querySelectorAll('a[href*="help.loomio.com"]')
                  .forEach(a => Object.assign(a, {
                    href: 'https://docs.framasoft.org/fr/loomio/index.html',
                  }));
              }
            }, 1000);
          };
          break;

        // no default
      }
    },
  },
};
</script>
