var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:`pb-4 px-2 info-${_vm.site}`,attrs:{"id":"fsb-info"}},[_c('b-button',{attrs:{"variant":" pl-0 text-uppercase mb-2"},on:{"click":function($event){return _vm.goBack()}}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('txt.back'))}})]),_vm._v(" "),_c('h2',{staticClass:"text-center"},[_c('a',{attrs:{"href":!_vm.$te(`fnav.sites.${_vm.site}.link`)
        ? _vm.$t(`link.${_vm.site}`)
        : _vm.$t(`fnav.sites.${_vm.site}.link`)}},[(_vm.$te(`dio.${_vm.site}`))?_c('img',{staticClass:"d-block mx-auto mb-2",attrs:{"alt":"","src":`${_vm.$t('baseurl')}img/icons/ati/sites/${_vm.site}.svg`}}):_vm._e(),_vm._v(" "),(_vm.site !== '' && !_vm.$te(`dio.${_vm.site}`))?_c('img',{staticClass:"d-block mx-auto mb-2 rounded-circle",attrs:{"alt":"","src":`${_vm.$t('baseurl')}img/icons/cards/${_vm.site}.jpg`}}):_vm._e(),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$te(`color.${_vm.site}`)
          ? _vm.$t(`txt.${_vm.site}`)
          : _vm.$t(`fnav.sites.${_vm.site}.t1`))}})])]),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(`fnav.sites.${_vm.site}.d1`))}}),_vm._v(" "),(_vm.hasScreen())?_c('p',{staticClass:"text-center"},[_c('img',{staticClass:"img-fluid",attrs:{"alt":"","src":`${_vm.$t('baseurl')}img/screens/${_vm.site}.png`}})]):_vm._e(),_vm._v(" "),(_vm.$te(`src.${_vm.site}`))?_c('p',[_vm._v("\n    Service basé sur\n    "),_c('a',{attrs:{"href":_vm.$t(`src.${_vm.site}`)},domProps:{"textContent":_vm._s(_vm.$t(`soft.${_vm.site}`))}})]):_vm._e(),_vm._v(" "),(/(calc|date|memo|pad|talk)/.test(_vm.site))?_c('b-alert',{attrs:{"show":"","variant":" fb-g3 mb-0 mt-4 p-2 d-flex align-items-center border-0"}},[_c('a',{attrs:{"href":_vm.$t('link.entraide'),"aria-hidden":"true"}},[_c('img',{staticClass:"rounded-circle ml-1 mr-3",staticStyle:{"width":"70px","height":"70px","min-width":"70px"},attrs:{"src":`${_vm.$t('baseurl')}img/icons/chatons/${_vm.site}.svg`,"alt":""}})]),_vm._v(" "),_c('p',{staticClass:"mb-0 py-2",domProps:{"innerHTML":_vm._s(_vm.$t('entraide'))}})]):_vm._e(),_vm._v(" "),(Object.keys(_vm.$t('deframa')).includes(_vm.site))?_c('b-alert',{attrs:{"show":"","variant":"warning mb-0 mt-4 p-2 d-flex align-items-center border-0"}},[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.$t('deframa.alert2', {
        service: _vm.$t(`color.${_vm.site}`),
        date: _vm.date(_vm.$t(`deframa.${_vm.site}[1]`)),
        link: `https://alt.framasoft.org/fr/${_vm.$t(`txt.${_vm.site}`, '-l')}`
      }))}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }