<template>
  <section id="f-contact-form">
    <div v-if="success.status === ''">
      <div
        class="subtitle"
        v-html="`${$t('feedback.contact')} <b>${$t('txt.soft')}</b>`"
      ></div>

      <b-card
        v-if="/(date|forms)/.test($t('site'))"
        no-body
        class="bg-light mb-4"
      >
        <b-card-body
          v-html="$t(`reminder.${$t('site')}`)"
        />
      </b-card>

      <b-form @submit.prevent="sendMail">
        <div>
          <!-- Name -->
          <b-form-group
            id="f-cf-name-wrapper"
            label-for="f-cf-name"
            label-sr-only
            :label="`${$t('contact.form.name')} (${$t('contact.form.required')})`"
          >
            <b-input-group>
              <b-input-group-prepend>
                <i class="fas fa-user fa-lg fa-fw fc-g6"></i>
              </b-input-group-prepend>
              <b-form-input
                id="f-cf-name"
                v-model="form['your-name']"
                :placeholder="$t('contact.form.name')"
                type="text"
                required
                trim
                :state="ok.name"
                @focusout="check('name')"
              />
            </b-input-group>
          </b-form-group>

          <!-- Email -->
          <b-form-group
            id="f-cf-email-wrapper"
            label-for="f-cf-email"
            label-sr-only
            :label="`${$t('contact.form.email')} (${$t('contact.form.required')})`"
          >
            <b-input-group>
              <b-input-group-prepend>
                <i class="far fa-envelope fa-lg fa-fw fc-g6"></i>
              </b-input-group-prepend>
              <b-form-input
                id="f-cf-email"
                v-model="form['your-email']"
                :placeholder="$t('contact.form.email')"
                type="email"
                required
                trim
                :state="ok.email"
                @focusout="check('email')"
              />
            </b-input-group>
            <p
              class="alert"
              v-html="$t('contact.alert.email')"
            ></p>
          </b-form-group>
        </div>
        <div>
          <!-- Subject -->
          <b-form-group
            id="f-cf-subject-wrapper"
            label-for="f-cf-subject"
            label-sr-only
            :label="`${$t('contact.form.subject')} (${$t('contact.form.required')})`"
          >
            <b-input-group>
              <b-input-group-prepend>
                <i class="fas fa-question fa-lg fa-fw fc-g6"></i>
              </b-input-group-prepend>
              <b-form-input
                id="f-cf-subject"
                v-model="form['your-subject']"
                :placeholder="$t('contact.form.subject')"
                type="text"
                required
                trim
                :state="ok.subject"
                @focusout="check('subject')"
              />
            </b-input-group>
          </b-form-group>

          <!-- Concern -->
          <b-form-group
            v-show="section !== 'contact-participate'"
            id="f-cf-concerne-wrapper"
            label-for="f-cf-concerne"
            label-sr-only
            :label="`${$t('contact.form.concerne')} (${$t('contact.form.required')})`"
          >
            <b-input-group>
              <b-input-group-prepend>
                <i class="fas fa-crosshairs fa-lg fa-fw fc-g6"></i>
              </b-input-group-prepend>
              <b-form-select
                id="f-cf-concerne"
                v-model="form.concerne"
                required
                :state="ok.concerne"
                @change="check('concerne')"
              >
                <option
                  v-if="!general && $t('site') !== 'soft'"
                  :value="this.$t(`txt.${this.$t('site')}`)"
                  v-text="this.$t(`txt.${this.$t('site')}`)"
                ></option>
                <option
                  value=""
                  v-text="'---'"
                ></option>
                <option
                  v-for="(label, index) in wpcf"
                  :key="index"
                  :value="label"
                  v-text="$t(`contact.concerne[${index}]`)"
                ></option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <!-- Message -->
          <b-form-group
            id="f-cf-message-wrapper"
            label-for="f-cf-message"
            label-sr-only
            :label="`${$t('contact.form.message')} (${$t('contact.form.required')})`"
          >
            <b-input-group>
              <b-input-group-prepend>
                <i class="fas fa-pen fa-lg fa-fw fc-g6"></i>
              </b-input-group-prepend>
              <b-form-textarea
                id="f-cf-message"
                v-model="form['your-message']"
                :placeholder="$t('contact.form.message')"
                cols="40"
                rows="10"
                required
                @focusin="bienveillance = true; check('concerne')"
                @focusout="check('message')"
              />
              <!-- <p
                v-show="form.concerne === 'Newsletter' && ok.email"
                class="alert alert-warning col-12 mx-0"
              >
                <span v-html="$t(`contact.alert.newsletter`)"></span>
                <Unsubscribe
                  :email="form['your-email']"
                  variant="light border btn-sm"
                />
              </p> -->
              <p
                v-show="false"
                class="alert alert-info"
                v-html="$t('contact.alert.kindness')
                  .replace('<a href', '<a target=\'_blank\' href')"
              ></p>
              <p
                v-if="noEmergency()"
                class="alert alert-dark col-12 mx-0 text-center"
              >
                <img
                  alt=""
                  class="img-fluid"
                  :src="`${$t('baseurl')}img/fire.webp`"
                />
              </p>
              <div
                v-if="noInclusive()"
                class="alert alert-info col-12 m-0"
              >
                <p
                  v-html="$t('contact.alert.inclusive')
                    .replace('<a href', '<a target=\'_blank\' href')"
                ></p>
                <a href="https://framablog.org/2018/08/13/ecriture-du-blog-nous-ne-transigerons-pas-sur-les-libertes/">
                  <b-card
                    class="col-md-8 mx-auto mb-2 p-0"
                    img-alt=""
                    img-src="https://framablog.org/wp-content/uploads/2018/08/shruggie-00.jpg"
                    img-top
                  >
                    <b-card-text>
                      <b class="h5">
                        Écriture du blog : nous ne transigerons pas sur les libertés
                      </b>
                      <br />
                      <span class="fc-g6">framablog.org</span>
                    </b-card-text>
                  </b-card>
                </a>
              </div>
              <div v-if="section === 'contact-faq'">
                <p
                  v-for="(alert, id) in $t('contact.alert')"
                  v-show="form.concerne === $t(`txt.${id}`)"
                  :key="id"
                  class="alert alert-warning"
                  v-html="$t(`contact.alert.${id}`)
                    .replace('<a href', '<a target=\'_blank\' href')"
                ></p>
                <p
                  class="alert alert-danger"
                  v-html="$t('contact.alert.mdp')
                    .replace('<a href', '<a target=\'_blank\' href')"
                ></p>
              </div>
            </b-input-group>
          </b-form-group>

          <!-- File -->
          <b-form-group
            id="f-cf-file-wrapper"
            label-for="f-cf-file"
            label-sr-only
            :label="`${$t('contact.form.file')} (${$t('contact.form.optional')})`"
          >
            <b-input-group
              :title="`${$t('contact.form.fileHelp')} ${ext.join(', ')}`"
            >
              <b-input-group-prepend>
                <i class="fas fa-paperclip fa-lg fa-fw fc-g6"></i>
              </b-input-group-prepend>
              <b-form-file
                id="f-cf-file"
                v-model="form['your-file']"
                plain
                :accept="ext.join()"
              />
            </b-input-group>
          </b-form-group>
        </div>

        <!-- Submit -->
        <b-button
          block
          :disabled="noUnsubscribe()"
          type="submit"
          variant="outline-warning text-center mb-3 border border-warning"
        >
          <span
            v-if="!submit"
            v-html="$t('contact.form.submit')"
          ></span>
          <span v-else>
            <b-spinner
              v-for="n in 3"
              :key="n"
              type="grow"
              variant="light spinner-grow-sm"
              :label="n === 1 ? $t('contact.form.sending') : ''"
              class="mx-1"
            />
          </span>
        </b-button>
      </b-form>
    </div>

    <div
      v-if="success.status === 'mail_sent'"
      class="confirm"
    >
      <i class="fas fa-check fa-2x fa-shape-circle fa-border fd-v5 fc-v5 fa-block-center"></i>
      <p
        class="alert"
        v-html="$t('contact.alert.sent')"
      ></p>
    </div>

    <div
      v-if="/failed$/.test(success.status)"
      class="confirm"
    >
      <i class="fas fa-xmark fa-2x fa-shape-circle fa-border fd-r5 fc-r5 fa-block-center"></i>
      <p
        class="alert"
        v-html="$t('contact.alert.failed')"
      ></p>
    </div>
  </section>
</template>

<script>
// import Unsubscribe from '../Unsubscribe.vue';

export default {
  components: {
    // Unsubscribe,
  },

  props: {
    section: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      wpcf: [ // labels in Wordpress Contact Form
        'Annonce d\'un événement libre',
        'Invitation à un événement',
        'Partenariat',
        'Contact presse',
        'Newsletter',
        'Questions sur l\'association',
        'Prestation / Emploi : réponse ou candidature spontanée',
      ],
      bienveillance: false,
      ext: [
        '.gif', '.png', '.jpg', '.jpeg', '.svg', '.pdf', '.txt', '.doc',
        '.docx', '.pps', '.ppt', '.pptx', '.odt', '.odg', '.odp', '.log',
        '.rtf', '.tex', '.csv', '.xls', '.xslx', '.vcf', '.xml', '.gpx',
        '.css', '.gz',
      ],
      form: {
        _wpcf7: '423',
        _wpcf7_version: '5.1.1',
        _wpcf7_locale: 'en_US',
        _wpcf7_unit_tag: 'wpcf7-f423-p5-o1',
        _wpcf7_container_post: '5',
        'g-recaptcha-response': '',
        'mailou-158': '',
        'your-name': '',
        'your-email': '',
        concerne: '', // with an 'e' for WP Contact Form
        'your-subject': '',
        'your-message': '',
        'your-file': null,
        'your-referrer': `${document.referrer} ; ${document.location.href}`,
        'your-languages': `${window.navigator.languages} Viewport : ${window.innerWidth}x${window.innerHeight}`,
      },
      general: !(Object.keys(this.$t('txt')).indexOf(this.$t('site')) > -1),
      ok: {
        name: null,
        email: null,
        subject: null,
        concerne: null,
        message: null,
      },
      success: {
        into: '',
        message: '',
        status: '',
      },
      submit: false,
    };
  },

  watch: {
    section(newValue) {
      if (!this.general && newValue === 'contact-faq') {
        this.form.concerne = this.$t(`txt.${this.$t('site')}`);
      }
      if (newValue === 'contact-participate') {
        this.form.concerne = 'Participer';
      }
    },
  },

  methods: {
    check(input) {
      const el = document.getElementById(`f-cf-${input}`);
      switch (input) {
        case 'email':
          if (el.validity.valueMissing) {
            this.ok.email = false;
            el.setCustomValidity(this.$i18n.messages[this.$i18n.locale].contact.form.error_empty);
          } else if (el.validity.typeMismatch) {
            this.ok.email = false;
            el.setCustomValidity(this.$i18n.messages[this.$i18n.locale].contact.form.error_email);
          } else {
            this.ok.email = true;
            el.setCustomValidity('');
          }
          break;
        default:
          if (el.validity.valueMissing || el.value === '') {
            this.ok[input] = false;
            el.setCustomValidity(this.$i18n.messages[this.$i18n.locale].contact.form.error_empty);
          } else {
            this.ok[input] = true;
            el.setCustomValidity('');
          }
          break;
      }
    },

    sendMail(e) {
      if (!this.submit) {
        this.submit = true;
        const sendData = new FormData();
        Object.entries(this.form).map(([key, val]) => sendData.append(key, val));

        fetch('https://contact.framasoft.org/wp-json/contact-form-7/v1/contact-forms/423/feedback', {
          method: 'POST',
          body: sendData,
        }).then(res => res.json())
          .then((data) => { this.success = data; })
          .catch(err => console.error(err)); // eslint-disable-line
      }
      e.preventDefault();
    },

    noUnsubscribe() {
      return false;
      /* this.form.concerne === 'Newsletter'
        && this.ok.email
        && /(desabonne|desinscri|unsubscribe|annul|suppr)/
          .test(this.$t(`${this.form['your-subject']} ${this.form['your-message']}`, '-klL@'));
      */
    },

    noEmergency() {
      return /(urgent|emergency|urgence)/
        .test(this.$t(`${this.form['your-subject']}`, '-klL@'));
    },

    noInclusive() {
      return /(ecriture-inclusi|point-median)/
        .test(this.$t(`${this.form['your-subject']} ${this.form['your-message']}`, '-klL@'));
    },
  },
};
</script>
