<template>
  <div>
    <section
      id="ft-intro"
      class="main-row f-bs4"
    >
      <b-container>
        <b-row>
          <b-col
            md="7"
            lg="6"
            class="pl-0"
          >
            <b-row
              class="buttons m-0"
              tag="ul"
            >
              <b-col
                md="6"
                tag="li"
                class="pb-2 pb-md-3 pl-0"
              >
                <b-button
                  block
                  :href="$t('hos.data.docs')"
                  size="lg"
                  variant="outline-warning"
                  onclick="return false;"
                  @click="docs = true"
                >
                  <i class="fas fa-circle-play mr-1"></i>
                  <span v-html="$t('team.intro.btn-tour')"></span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          id="learn-more"
          aria-hidden="true"
          align-h="center"
          class="d-none d-sm-flex"
        >
          <input
            id="btn-learn-more"
            type="checkbox"
            class="sr-only"
            @change="$parent.$children[2].$children[1].$refs['ft-what']
              .scrollIntoView({ behavior: 'smooth' })"
          />
          <label
            for="btn-learn-more"
            class="btn btn-sm btn-link p-0"
          >
            <span v-html="$t('team.intro.more', $t('hos.data'))"></span>
            <i class="fas fa-lg fa-chevron-down d-block fc-g8"></i>
          </label>
        </b-row>
      </b-container>
    </section>

    <modal-docs
      :link="$t('hos.data.docs')"
      :open="docs"
    />
  </div>
</template>

<script>
import ModalDocs from '../../../../commons/components/ModalDocs.vue';

export default {
  components: {
    ModalDocs,
  },

  data() {
    return {
      docs: false,
    };
  },
};
</script>

<style lang="scss">
#ct-login #root {
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.2));
  padding: 0.5rem 0 1rem;
  position: relative;
  z-index: 1; /* keep dropdown-menu visible */

  @media (max-width: 480px) {
    max-height: 600px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: var(--f-f7);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
    }
  }

  &::after {
    background: var(--f-g5);
    background-image: url('../../../../public/img/team/intro.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
      clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  h1 {
    color: var(--f-d1);
    font-size: 5rem !important;
    font-weight: 300;
    margin: 4rem 0 3rem !important;

    @media (max-width: 992px) {
      font-size: 4rem !important;
      margin: 3rem 0 2rem !important;
    }

    @media (max-width: 768px) {
      font-size: 3rem !important;
      margin: 2rem 0 1.5rem !important;
    }
  }

  h2 {
    color: var(--f-d1);
    font-size: 2rem !important;
    font-weight: 300;
    line-height: 1.25;
    margin: 0;

    @media (max-width: 992px) {
      max-width: 75%;
      font-size: 1.75rem !important;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem !important;
    }
  }
}

#ft-intro.f-bs4 {
  #learn-more {
    margin-top: 3rem;

    label {
      color: var(--f-d1);
      background: none;
      text-decoration: none;

      i::before {
        color: var(--f-d1);
      }
    }

    span {
      padding: .25rem ;
      font-size: .875rem;
    }

    input:checked ~ label {
      visibility: hidden;
    }
  }

 .buttons {
    margin: 0 0 5.5rem !important;
    padding: 0;
    list-style: none;
    max-width: 90%;

    @media (max-width: 768px) {
      margin: 0 0 1.5rem !important;
    }

    @media (max-width: 1200px) {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      max-width: 45%;
    }

    @media (max-width: 576px) {
      max-width: 66%;
    }

    > li {
      display: flex;
      align-items: center;

      > div > .btn,
      > .btn {
        display:flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0.5rem 1rem;
        white-space: normal;

        @media (max-width: 992px) {
          font-size: 1rem;
        }

        @media (max-width: 576px) {
          font-size: .875rem;
        }
      }

      .btn-outline-warning {
        background: #fff;
        border-color: #fff;
        font-weight: bold;

        &:hover, &:focus, &:active {
          background: var(--f-o7);
          border-color: var(--f-o7);
          color: #fff;
        }
      }
    }
  }

  li:last-of-type .btn-outline-light {
    border: transparent;

    i.fas {
      margin-right: 0.25rem;
    }
  }
}
</style>
