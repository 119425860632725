<template>
  <header
    id="ft-header"
    class="f-bs4"
  >
    <b-container class="px-md-0">
      <b-row
        align-v="center"
        class="justify-content-md-between"
      >
        <b-col
          class="text-center text-md-left"
          md="5"
        >
          <h1 class="d-inline-block">
            <a href="/">
              <img
                alt=""
                :src="`${$t('baseurl')}img/icons/ati/sites/team.svg`"
              />
              <span v-html="$t('color.team')"></span>
            </a>
          </h1>
        </b-col>
        <b-col
          class="d-flex justify-content-center justify-content-md-end"
          md="7"
        >
          <b-nav
            class="list-unstyled justify-content-center"
            pills
          >
            <b-nav-text>
              <a
                class="btn btn-outline-secondary text-uppercase mx-1 border-0"
                href="https://mattermost.com/download/#mattermostApps"
                v-html="$t('team.header.apps')"
              ></a>
            </b-nav-text>
            <b-nav-text>
              <a
                class="btn btn-outline-secondary text-uppercase mx-1 border-0"
                :href="`${$t('link.team')}/signup_user_complete`"
                v-html="$t('team.header.signup')"
              ></a>
            </b-nav-text>
            <b-nav-text>
              <a
                class="btn btn-primary text-uppercase mx-1 border-0"
                :href="`${$t('link.team')}/login`"
                onclick="if (document.getElementById('login_section')) {
                  document.getElementById('login_section')
                    .scrollIntoView({ behavior: 'smooth' });
                } else {
                  window.location.href = `${this.$t('link.team')}/login`;
                };
                return false;"
                v-html="$t('team.header.signin')"
              ></a>
            </b-nav-text>
          </b-nav>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
export default {
  methods: {
    goToLoginForm() {

    },
  },
};
</script>

<style lang="scss">
  #ft-header {
    background: #fff;
    border-bottom: 1px solid var(--f-g4);
    position: relative;

    h1 {
      margin: 0;
      line-height: normal;
      font-size: 2rem !important;

      a {
        text-decoration: none;
        display: flex;
        align-items: center;

        img {
          width: 64px;
          height: 64px;
          padding: .25rem .25rem .25rem 0;
        }
      }
    }

    .btn {
      border: transparent;
    }
  }
</style>
