<template>
  <section
    id="fsb-category"
    class="m-n2"
  >
    <div role="tablist">
      <div
        v-for="category in Object.keys($t('fnav.categories'))"
        :key="category"
        :class="category"
      >
        <!-- Category title -->
        <b-button
          v-b-toggle="category"
          block
          variant=" rounded-0 text-left shadow-none c-lvl1"
        >
          <span v-html="$t(`fnav.categories.${category}.title`)"></span>
          <i class="fas fa-chevron-right"></i>
          <i class="fas fa-chevron-left"></i>
        </b-button>
        <!-- Category content -->
        <b-collapse
          :id="category"
          accordion="categories"
          class="c-lvl1"
          role="tabpanel"
        >
          <!-- Category main items -->
          <ul class="text-center list-unstyled row m-0 pb-2 justify-content-center">
            <li
              v-for="l in categories[`c-${category}`]"
              :key="l"
              :class="`col-6 mt-2 fs-${l}`"
            >
              <Item :site="l" />
            </li>
          </ul>
          <div
            v-for="key in Object.keys($t(`fnav.categories.${category}`))
              .filter(k => !/^(title|dio-share|dio-search)$/.test(k))"
            :key="key"
          >
            <!-- Sub category title -->
            <b-button
              v-b-toggle="key"
              block
              variant=" rounded-0 text-left shadow-none c-lvl2"
            >
              <span v-html="$t(`fnav.categories.${category}.${key}`)"></span>
              <i class="fas fa-chevron-down"></i>
              <i class="fas fa-chevron-up"></i>
            </b-button>
            <!-- Sub category content -->
            <b-collapse
              :id="key"
              accordion="subcategories"
              class="c-lvl2"
              role="tabpanel"
            >
              <!-- Sub category item -->
              <ul class="text-center list-unstyled row m-0 pb-2 justify-content-center">
                <li
                  v-for="l in categories[`c-${key}`]"
                  :key="l"
                  :class="`col-6 mt-2 fs-${l}`"
                >
                  <Item :site="l" />
                </li>
              </ul>
            </b-collapse>
          </div>
        </b-collapse>
      </div>
    </div>
  </section>
</template>

<script>
import Item from './Item.vue';

export default {
  components: {
    Item,
  },

  props: {
    categories: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.$root.$on('bv::collapse::state', (id, shown) => {
      const SidebarMenu = this.$root
        .$children[0] // App.vue
        .$children[0] // Nav.vue
        .$children.find(child => child.$options._componentTag === 'sidebar-menu'); // eslint-disable-line no-underscore-dangle
      const level = +id.includes('-');

      if (!id.includes('f-b-sidebar')) {
        if (shown) {
          SidebarMenu.collapse[level] = id;
        } else if (id === SidebarMenu.collapse[level]) {
          SidebarMenu.collapse[level] = '';
        }
      }
      this.$emit('collapseChange');
    });
  },
};
</script>
