<template>
  <b-modal
    id="f-modal-about"
    v-model="state.about"
    button-size="sm"
    :cancel-title-html="$t('txt.close')"
    cancel-variant="light"
    :hide-header="true"
    :lazy="true"
    scrollable
    :static="true"
    size="lg"
    :title="$t('about.title', { name: $t('txt.soft') })"
    :title-sr-only="true"
    @hidden="close()"
  >
    <section v-if="$te(`txt.${$t('site')}`) && $t('site') !== 'soft'">
      <h1 class="h2 text-center">
        <span v-html="$t('about.title', { name: $t(`color.${$t('site')}`) })"></span>
        <br />
        <img
          v-if="$te(`icon.${$t('site')}`)"
          alt=""
          class="m-3 logo-80 d-inline"
          :src="`${$t('baseurl')}img/icons/ati/sites/${$t('site')}.svg`"
        />
      </h1>

      <b-row class="m-0">
        <b-col
          class="mx-auto"
          cols="8"
        >
          <p
            v-if="$te(`fnav.sites.${$t('site')}.d1`)"
            v-html="$t(`fnav.sites.${$t('site')}.d1`)"
          ></p>

          <b-row>
            <b-col cols="6">
              <ul class="list-unstyled">
                <li>
                  <a
                    class="my-2 btn btn-outline-warning btn-block"
                    :href="$t('fnav.sites.doc.link')"
                  >
                    <i :class="`${$t('icon.docs')} fa-fw`"></i>
                    <span v-html="$t('fnav.sites.doc.name')"></span>
                  </a>
                </li>
                <li
                  v-if="$te(`src.${$t('site')}`)"
                >
                  <a
                    class="my-2 btn btn-outline-info btn-block"
                    :href="$t(`src.${$t('site')}`)"
                  >
                    <i class="fab fa-git fa-fw"></i>
                    <span v-html="$t(`soft.${$t('site')}`)"></span>
                  </a>
                </li>
                <li
                  v-if="$te(`git.${$t('site')}`)"
                >
                  <a
                    class="my-2 btn btn-outline-info btn-block"
                    :href="$t(`git.${$t('site')}`)"
                  >
                    <i class="fas fa-code-branch fa-fw"></i>
                    <span v-html="$t(`txt.git`)"></span>
                  </a>
                </li>
              </ul>
            </b-col>
            <b-col cols="6">
              <ul class="list-unstyled">
                <li>
                  <a
                    class="my-2 btn btn-outline-primary btn-block"
                    :href="$t('fnav.sites.charte.link')"
                  >
                    <i :class="`${$t('icon.charte')} fa-fw`"></i>
                    <span v-html="$t('fnav.sites.charte.name')"></span>
                  </a>
                </li>
                <li>
                  <a
                    class="my-2 btn btn-outline-warning btn-block"
                    :href="$t('fnav.sites.moderation.link')"
                  >
                    <i :class="`${$t('icon.moderation')} fa-fw`"></i>
                    <span v-html="$t('fnav.sites.moderation.name')"></span>
                  </a>
                </li>
                <li>
                  <a
                    class="my-2 btn btn-outline-secondary btn-block"
                    :href="$t('fnav.sites.cgu.link')"
                  >
                    <i class="fas fa-file-contract fa-fw"></i>
                    <span v-html="$t('fnav.sites.cgu.name')"></span>
                  </a>
                </li>
              </ul>
            </b-col>
          </b-row>
          <p class="text-right">
            <a
              :href="$t('fnav.sites.credits.link').replace(/$/, `/#${$t('site')}`)"
              v-html="$t('fnav.sites.credits.name')"
            ></a>
          </p>
        </b-col>
      </b-row>

      <hr class="w-25 mb-4 mt-3 mx-auto" />
    </section>
    <section id="about-framasoft">
      <b-row>
        <b-col
          class="mx-auto"
          cols="10"
        >
          <h1 class="h2 text-center">
            <span v-html="$t('about.title', { name: $t('color.soft') })"></span>
            <br />
            <img
              alt=""
              class="m-3 logo-80 d-inline"
              :src="`${$t('baseurl')}img/icons/ati/sites/soft.svg`"
            />
          </h1>
          <div>
            <p v-html="$t('about.intro')"></p>
            <ul class="text-right list-inline">
              <li class="list-inline-item m-0">
                <a
                  class="btn btn-link px-0"
                  :href="$t('fnav.sites.asso.link')"
                >
                  <i :class="`${$t('icon.asso')} fa-fw fc-g6`"></i>
                  <span v-html="$t('fnav.sites.asso.name')"></span>
                </a> –
              </li>
              <li class="list-inline-item m-0">
                <a
                  class="btn btn-link px-0"
                  :href="$t('fnav.sites.legals.link')"
                >
                  <i :class="`${$t('icon.legals')} fa-fw fc-g6`"></i>
                  <span v-html="$t('fnav.sites.legals.name')"></span>
                </a> –
              </li>
              <li class="list-inline-item">
                <a
                  class="btn btn-link px-0"
                  :href="$t('fnav.sites.stats.link')"
                >
                  <i :class="`${$t('icon.stats')} fa-fw fc-g6`"></i>
                  <span v-html="$t('fnav.sites.stats.name')"></span>
                </a>
              </li>
            </ul>
          </div>

          <div
            v-if="false"
            v-html="$t('about.values')"
          ></div>

          <div v-html="$t('about.actions')"></div>
          <ul class="text-right list-inline">
            <li class="list-inline-item m-0">
              <a
                class="btn btn-link px-0"
                :href="$t('fnav.sites.manifest.link')"
              >
                <i :class="`${$t('icon.charte')} fa-fw fc-g6`"></i>
                <span v-html="$t('fnav.sites.manifest.name')"></span>
              </a> –
            </li>
            <li class="list-inline-item m-0">
              <a
                class="btn btn-link px-0"
                :href="$t('fnav.sites.charte.link')"
              >
                <i :class="`${$t('icon.charte')} fa-fw fc-g6`"></i>
                <span v-html="$t('fnav.sites.charte.name')"></span>
              </a> –
            </li>
            <li class="list-inline-item m-0">
              <a
                class="btn btn-link px-0"
                :href="$t('fnav.sites.moderation.link')"
              >
                <i :class="`${$t('icon.moderation')} fa-fw fc-g6`"></i>
                <span v-html="$t('fnav.sites.moderation.name')"></span>
              </a> –
            </li>
            <li class="list-inline-item">
              <a
                class="btn btn-link px-0"
                :href="$t('fnav.sites.cgu.link')"
              >
                <i class="fas fa-file-contract fa-fw fc-g6"></i>
                <span v-html="$t('fnav.sites.cgu.name')"></span>
              </a>
            </li>
          </ul>
        </b-col>
      </b-row>
    </section>
  </b-modal>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      state: {
        about: false,
      },
    };
  },

  watch: {
    open(newValue) {
      if (newValue) {
        this.state.about = true;
      }
    },
  },

  methods: {
    close() {
      this.state.about = false;
      this.$emit('close');
    },
  },
};
</script>
