<template>
  <section id="fsb-grid">
    <div
      v-for="i in pages.length"
      :key="i"
    >
      <h2
        class="h4 sticky-top mx-1 mb-0 mt-3 text-uppercase"
        v-html="pages[i - 1][0][0]"
      ></h2>
      <ul class="text-center list-unstyled row m-0">
        <li
          v-for="l in pages[i - 1]"
          :key="l"
          :class="`col-6 mt-2 px-1 fs-${l}`"
        >
          <Item
            :site="l"
            view="grid"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Item from './Item.vue';

export default {
  components: {
    Item,
  },

  props: {
    pages: {
      type: Array,
      required: true,
    },
  },
};
</script>
