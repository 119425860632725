<template>
  <section
    id="fsb-search"
    class="px-2"
  >
    <div class="search-results">
      <div
        v-for="key in Object.keys($t('searchLabels'))"
        :key="key"
        :class="searchClass(key)"
      >
        <h2
          class="h5"
          v-html="$t(`searchLabels.${key}`)"
        ></h2>
        <ul class="list-group">
          <li
            v-for="l in categories[`c-${key}`]"
            :key="l"
            :class="`list-group-item border-0 ${
              result.includes(l) ? 'search' : ''
            } fs-${l}`"
          >
            <button
              v-if="$te(`fnav.sites.${l}.d1`) && $t(`taxonomy.${l}[0]`) !== 'c-follow'"
              class="btn btn-link float-right my-1 p-0"
              :title="$t('txt.info-about', {site: l})"
              @click="goToInfo(l)"
            >
              <i class="fas fa-circle-info fc-g6"></i>
              <span
                class="sr-only"
                v-html="$t('txt.infos')"
              ></span>
            </button>
            <a
              class="text-decoration-none"
              :href="/^fnav/.test($t(`fnav.sites.${l}.link`))
                ? $t(`link.${l}`)
                : $t(`fnav.sites.${l}.link`)"
              rel="noopener"
              target="_blank"
            >
              <i :class="`${$t(`icon.${l}`)} fa-lg fa-fw fc-g6`"></i>
              <span
                v-html="$te(`color.${l}`)
                  ? $t(`color.${l}`)
                  : $t(`fnav.sites.${l}.t1`)"
              ></span>
              <i class="fas fa-up-right-from-square fa-xs fc-d6"></i>
              <span
                class="sr-only"
                v-html="`(${$t('txt.newWindow')})`"
              ></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="search.length > 2"
      class="exo-search"
    >
      <hr />
      <p class="mb-2">
        <span v-html="$t('txt.search')"></span>
        <i><b v-text="search"></b></i>
        <span v-html="$t('txt.on')"></span>
      </p>
      <b-row class="no-gutters mx-n1">
        <b-col class="m-1">
          <b-button
            block
            :href="`https://duckduckgo.com/?q=${search}`"
            rel="noopener"
            size="sm"
            target="_blank"
            variant="light border text-left"
          >
            <i class="fas fa-magnifying-glass fa-sm fa-shape-circle fa-white fc-g6"></i>
            <span v-html="$t('search.web')"></span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="no-gutters mx-n1">
        <b-col class="m-1">
          <b-button
            block
            :href="`https://duckduckgo.com/?q=${search} (site:${framaworld}`"
            rel="noopener"
            size="sm"
            target="_blank"
            variant="light border text-left"
          >
            <i class="fas fa-magnifying-glass fa-sm fa-shape-circle fa-white fc-f6"></i>
            <span v-html="$t('search.frama')"></span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="no-gutters mx-n1">
        <b-col class="m-1">
          <b-button
            block
            :href="`https://framablog.org/?s=${search}`"
            rel="noopener"
            size="sm"
            target="_blank"
            variant="light border text-left"
          >
            <i :class="`${$t('icon.blog')} fa-sm fa-shape-circle fa-white fc-o7`"></i>
            <span v-html="$t('search.blog')"></span>
          </b-button>
        </b-col>
        <b-col class="m-1">
          <b-button
            block
            :href="`https://framacolibri.org/search?q=${search}`"
            rel="noopener"
            size="sm"
            target="_blank"
            variant="light border text-left"
          >
            <i :class="`${$t('icon.colibri')} fa-sm fa-shape-circle fa-white fc-o6`"></i>
            <span v-html="$t('search.colibri')"></span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="no-gutters mx-n1">
        <b-col class="m-1">
          <b-button
            block
            :href="`https://framalibre.org/recherche-par-crit-res?keys=${search}`"
            rel="noopener"
            size="sm"
            target="_blank"
            variant="light border text-left"
          >
            <i :class="`${$t('icon.libre')} fa-sm fa-shape-circle fa-white fc-f7`"></i>
            <span v-html="$t('search.libre')"></span>
          </b-button>
        </b-col>
        <b-col class="m-1">
          <b-button
            block
            :href="`https://framatube.org/search?search=${search}&searchTarget=local`"
            rel="noopener"
            size="sm"
            target="_blank"
            variant="light border text-left"
          >
            <i :class="`${$t('icon.tube')} fa-sm fa-shape-circle fa-white fc-o7`"></i>
            <span v-html="$t('search.tube')"></span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    categories: {
      type: Object,
      required: true,
    },

    search: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      framaworld: [
        'degooglisons-internet.org', 'contributopia.org',
        'framalibre.org', 'framablog.org', 'framabook.org', 'framatube.org',
        'framacolibri.org', 'participer.framasoft.org',
        'docs.framasoft.org', 'framacloud.org',
      ].join(' OR site:').replace(/$/, ')'),
      result: [],
    };
  },

  watch: {
    search(newValue) {
      if (newValue !== '') {
        this.searchUpdate();
      }
    },
  },

  mounted() {
    if (this.search === '  ') {
      this.searchUpdate();
    }
  },

  methods: {
    goToInfo(site) {
      this.$root
        .$children[0] // App.vue
        .$children[0] // Nav.vue
        .$children.find(child => child.$options._componentTag === 'sidebar-menu') // eslint-disable-line no-underscore-dangle
        .$emit('goToInfo', site);
    },

    searchClass(key) {
      return ((key === 'dio' && /dio/.test(this.result.join(' ')))
        || this.result.includes(`c-${key}`))
        ? `search c-${key}`
        : `c-${key}`;
    },

    searchUpdate() {
      this.result = [];

      if (this.search.length > 1) {
        Object.keys(this.categories).forEach((j) => {
          this.categories[j].forEach((id) => {
            const words = this.$t(this.search.replace(/\+ ?/, '_'), '-kLl@').split('_');
            const reg = new RegExp(`(${words.join('|')})`, 'g');
            const content = [
              this.$t(`txt.${id}`), /* Framastuff */
              this.$t(`fnav.sites.${id}.t1`), /* Short description */
              this.$t(`fnav.sites.${id}.d1`), /* Long description */
            ].join(' ');
            if (reg.test(this.$t(content, '-kLl@'))) {
              this.result.push(id);
              this.result.push(j);
            }
          });
        });
      }
    },
  },
};
</script>
